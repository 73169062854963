import { FETCH_RAW_CRC_PM_LIST, FETCH_RAW_CRC_PM_LIST_SUCCESS, FETCH_RAW_CRC_PM_LIST_FAILURE, FETCH_RAW_CM_CRC_LIST, FETCH_RAW_CM_CRC_LIST_SUCCESS, FETCH_RAW_CM_CRC_LIST_FAILURE, FETCH_RAW_PROJECT_SUMMERY_LIST, FETCH_RAW_PROJECT_SUMMERY_LIST_SUCCESS, FETCH_RAW_PROJECT_SUMMERY_LIST_FAILURE, FETCH_RAW_ZPALGLOBAL_LIST, FETCH_RAW_ZPALGLOBALLIST_SUCCESS, FETCH_RAW_ZPALGLOBAL_FAILURE } from './types';


// RAW CRC PM list
const fetchRawCrcPmList = (payload) => ({
  type: FETCH_RAW_CRC_PM_LIST,
  ...payload
});

const fetchRawCrcPmListSuccess = (payload) => ({
  type: FETCH_RAW_CRC_PM_LIST_SUCCESS,
  ...payload
});

const fetchRawCrcPmListFailure = () => ({
  type: FETCH_RAW_CRC_PM_LIST_FAILURE
});

// RAW CM CRC list
const fetchRawCmCrcList = (payload) => ({
  type: FETCH_RAW_CM_CRC_LIST,
  ...payload
});

const fetchRawCmCrcListSuccess = (payload) => ({
  type: FETCH_RAW_CM_CRC_LIST_SUCCESS,
  ...payload
});

const fetchRawCmCrcListFailure = () => ({
  type: FETCH_RAW_CM_CRC_LIST_FAILURE
});

// Project summery CRC list
const fetchRawProjectSummeryList = (payload) => ({
  type: FETCH_RAW_PROJECT_SUMMERY_LIST,
  ...payload
});

const fetchRawProjectSummeryListSuccess = (payload) => ({
  type: FETCH_RAW_PROJECT_SUMMERY_LIST_SUCCESS,
  ...payload
});

const fetchRawProjectSummeryListFailure = () => ({
  type: FETCH_RAW_PROJECT_SUMMERY_LIST_FAILURE
});

// RAW CM CRC list
const fetchZPALGLOBALList = (payload) => ({
  type: FETCH_RAW_ZPALGLOBAL_LIST,
  ...payload
});

const fetchZPALGLOBALListSuccess = (payload) => ({
  type: FETCH_RAW_ZPALGLOBALLIST_SUCCESS,
  ...payload
});

const fetchZPALGLOBALListFailure = () => ({
  type: FETCH_RAW_ZPALGLOBAL_FAILURE
});



export {
  fetchRawCrcPmList, fetchRawCrcPmListSuccess, fetchRawCrcPmListFailure,
  fetchRawCmCrcList, fetchRawCmCrcListSuccess, fetchRawCmCrcListFailure,
  fetchRawProjectSummeryList, fetchRawProjectSummeryListSuccess, fetchRawProjectSummeryListFailure,
  fetchZPALGLOBALList,fetchZPALGLOBALListSuccess,fetchZPALGLOBALListFailure
};