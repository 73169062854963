import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { Button, Select, Pagination, Spin } from 'antd';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import * as XLSX from 'xlsx';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { fetchRawProjectSummeryList } from '../../../redux/projectMargin/action';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
//ag grid enterprise
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import axiosConfig from './axiosConfig';
import { cellFormatter } from '../Uitiles';
ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule,
    StatusBarModule,
    RowGroupingModule
]);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE)

const { Option } = Select;

const RawSummery = (props) => {
    const { onFetchRawProjectSummeryList, rawProjectSummeryList, isRawProjectSummeryListLoading } = props;

    const [theme, setTheme] = useState('ag-theme-material-dark'); // Default theme
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [pageSize, setPageSize] = useState(60000);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectModel, setSelectModel] = useState([]);
    const [loadingRows, setLoadingRows] = useState(false);

    const [columnDefs, setColumnDefs] = useState([
        { field: 'ID', headerName: 'ID', editable: false, filter: 'agMultiColumnFilter', rowDrag: true, enablePivot: false },
        { field: 'Year_Period', headerName: 'Year-Period', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'period', headerName: 'Period', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'docDate', headerName: 'Doc Date', editable: true, filter: 'agDateColumnFilter', minWidth: 190 },
        { field: 'coCode', headerName: 'CoCode', editable: true, filter: 'agMultiColumnFilter', filterParams: { applyMiniFilterWhileTyping: true } },
        { field: 'profitCtr', headerName: 'Profit Ctr', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'costCtr', headerName: 'Cost Ctr', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'trPrt', headerName: 'Tr.Prt', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'account', headerName: 'Account', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'gLAcctLongText', headerName: 'G/L Acct Long Text', editable: true, filter: 'agMultiColumnFilter', tooltipField: 'gLAcctLongText' },
        { field: 'documentNo', headerName: 'Document No', editable: true, filter: 'agMultiColumnFilter', enablePivot: false },
        { field: 'salesOrd', headerName: 'Sales ord.', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'order', headerName: 'Order', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'material', headerName: 'Material', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'purDoc', headerName: 'Pur. Doc.', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'vendCustNo', headerName: 'Vend/Cust No', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'wbsElement', headerName: 'WBS element', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'userName', headerName: 'User name', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'amountInDocCurr', headerName: 'Amount in doc. curr.', headerTooltip: 'Amount in doc. curr.', editable: true, filter: 'agMultiColumnFilter', enablePivot: false,cellStyle: { textAlign: 'right' },valueFormatter: cellFormatter },
        { field: 'curr', headerName: 'TC', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'amountInLocalCurr', headerName: 'Amount in local cur.', headerTooltip: 'Amount in local cur.', editable: true, filter: 'agMultiColumnFilter', enablePivot: false ,cellStyle: { textAlign: 'right' },valueFormatter: cellFormatter},
        { field: 'lCurr', headerName: 'LC', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'reference', headerName: 'Reference', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'assignment', headerName: 'Assignment', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'documentHeaderText', headerName: 'Document Header Text', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'text', headerName: 'Text', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'Proj_Master', headerName: 'Proj Master', editable: true, filter: 'agMultiColumnFilter' },
        // { field: 'FXReference', headerName: 'FX Reference', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'AmountUSD', headerName: 'Amount USD', editable: true, filter: 'agMultiColumnFilter', enablePivot: false,cellStyle: { textAlign: 'right' },valueFormatter: cellFormatter },
     
       { field: 'year', headerName: 'Year', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'Cust_Name', headerName: 'Cust Name', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'Channel', headerName: 'Channel', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'Channel2', headerName: 'Channel2', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'WBS_Name', headerName: 'WBS Name', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'WBS_Status', headerName: 'WBS Status', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'WBS_Element2', headerName: 'WBS Element2', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PROJECT_NAMES', headerName: 'PROJECT NAMES', editable: true, filter: 'agMultiColumnFilter', tooltipField:'PROJECT_NAMES' },
        { field: 'WBS_Level', headerName: 'WBS Level', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'WBS_Element_Name', headerName: 'WBS Element Name', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PC_Name', headerName: 'PC Name', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'GL_Grouping_L1', headerName: 'GL Grouping L1', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'GL_Category3', headerName: 'GL Category3', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'GL_Category2', headerName: 'GL Category2', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'GL_Description', headerName: 'GL Description', editable: true, filter: 'agMultiColumnFilter', tooltipField:'GL_Description' },
        { field: 'Proj_Description', headerName: 'Proj Description', editable: true, filter: 'agMultiColumnFilter', tooltipField:'Proj_Description' },
        { field: 'Product_Line', headerName: 'Product Line', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Channel1', headerName: 'PMP Channel1', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Channel2', headerName: 'PMP Channel2', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Project_Number', headerName: 'PMP Project Number', editable: true, filter: 'agMultiColumnFilter', enablePivot: false },
        { field: 'PMP_Project_Name', headerName: 'PMP Project Name', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_CoCode', headerName: 'PMP CoCode', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Country_Of_Work', headerName: 'PMP Country Of Work', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Location', headerName: 'PMP Location', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Market', headerName: 'PMP Market', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Customer', headerName: 'PMP Customer', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Framework_Agreement', headerName: 'PMP Framework Agreement', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMW_WBS_Element', headerName: 'PMW WBS Element', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMW_WBS_Element_Name', headerName: 'PMW WBS Element Name', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMW_Revenue_Type', headerName: 'PMW Revenue Type', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Risk_Profile', headerName: 'PMP Risk Profile', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Service', headerName: 'PMP Service', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'Assignment_Proj_Tag', headerName: 'Assignment Proj Tag', editable: true, filter: 'agMultiColumnFilter' },
    ]);
    
    useEffect(() => {
        if(rawProjectSummeryList.length==0 && !isRawProjectSummeryListLoading){
            onFetchRawProjectSummeryList({ tableName: 'Project_Summary_Consol', pageNumber: pageNumber, pageSize: pageSize });
        }
    }, [])

    const fetchAllData = async (totalPages) => {
        console.log("fetch summery api called")
        setLoadingRows(true); // Set loading state to true while fetching
        try {
            const allDataPromises = Array.from({ length: totalPages - 1 }, (_, i) => i + 2).map(pageNum =>
                axiosConfig.get(`/GeProcesstList?Table_Name=Project_Summary_Consol&pageNumber=${pageNum}&pageSize=${pageSize}`)
            );
            const allResponses = await Promise.all(allDataPromises);
            const allData = allResponses.flatMap(res => res.data?.data || []);
            setRowData(prevData => [...(prevData || []), ...allData]);
        } catch (error) {
            console.error('Error fetching additional data:', error);
        } finally {
            setLoadingRows(false); // Set loading state to false after fetching
        }
    };

    // Check for changes in rawProjectSummeryList and fetch more data if needed
    useEffect(() => {
        if (rawProjectSummeryList.length > 0) {
            setRowData(rawProjectSummeryList);
            const totalCount = rawProjectSummeryList[0]?.totalCount || 0; // Get total count from raw data
            const totalPage = Math.ceil(totalCount / pageSize); // Calculate total pages
            // fetchAllData(totalPage); // Fetch additional data
        }
    }, [rawProjectSummeryList]);

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const binaryStr = event.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

            const columns = sheet[0].map((header, index) => ({
                field: header,
                editable: true,
                filter: true,
                enableValue: header !== 'name' && header !== 'id',
                rowDrag: index === 0 ? true : false
            }));

            const rows = sheet.slice(1).map((row) =>
                row.reduce((acc, value, index) => {
                    acc[sheet[0][index]] = value;
                    return acc;
                }, {})
            );

            setColumnDefs(columns);
            // setRowData(rows);
        };
        reader.readAsBinaryString(file);
    };

    const onRowDragEnd = (event) => {
        const { node, overNode } = event;
        if (!overNode) return;

        const draggedRow = node.data;
        const fromIndex = node.rowIndex;
        const toIndex = overNode.rowIndex;

        if (fromIndex === toIndex) return;

        const newRowData = [...rowData];
        newRowData.splice(fromIndex, 1);
        newRowData.splice(toIndex, 0, draggedRow);

        setRowData(newRowData);
    };

    //   useEffect(() => {
    //     if (layoutSave && gridRef) {
    //         (async () => {
    //             let layouts = {}
    //             await saveGridLayout(gridRef,currentTab,layouts,setLayoutSave);
    //         })();
           
    //     }
      

    // }, [layoutSave])

    // useEffect(() => {
    //     if (layoutLoad && gridRef)
    //     {
    //         (async () => {
    //             await restoreGridLayout(gridRef, currentTab,);
    //             setLayoutLoad(false)
    //         })();
           
            
    //     }
    // }, [layoutLoad])

   

    const gridStyle = useMemo(() => ({ height: "99%", width: "100%" }), []);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 200,
            floatingFilter: true,
            resizable: true,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
            filter: true,
        };
    }, []);
      const currentTab = 'Project Summary Console'
    const defaultExcelExportParams = useMemo(() => {
        return {
            fileName: `${currentTab}.xlsx`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
            sheetName: "Sheet1", // Default sheet name
        };
    }, [currentTab]);
    const defaultCsvExportParams = useMemo(() => {
        return {
            fileName: `${currentTab}.csv`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
        };
    }, [currentTab]); 

    const rowSelection = useMemo(() => {
        return { mode: "multiRow" };
    }, []);

    const onGridReady = useCallback((params) => {
        if (isRawProjectSummeryListLoading) {
            params.api.showLoadingOverlay();
        } else {
            params.api.hideOverlay();
        }
    }, [isRawProjectSummeryListLoading]);

    // Update loading state
    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            if (isRawProjectSummeryListLoading) {
                gridRef.current.api.showLoadingOverlay();
            } else {
                gridRef.current.api.hideOverlay();
            }
        }
    }, [isRawProjectSummeryListLoading]);

    // Update loading state
    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            if (loadingRows) {
                gridRef.current.api.showLoadingOverlay();
            } else {
                gridRef.current.api.hideOverlay();
            }
        }
    }, [loadingRows]);

    const [statusBar] = useState({
        statusPanels: [
            {
                statusPanel: 'agTotalRowCountComponent',
                align: 'left'
            },
            {
                statusPanel: 'agSelectedRowCountComponent',
                align: 'left'
            },
            {
                statusPanel: 'agFilteredRowCountComponent',
                align: 'left'
            }
        ]
    });
    
    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 200,
            pinned: "left",
        };
    }, []);

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        console.log('Selected Row Details:', selectedRows);
        setSelectModel(selectedRows)
        // You can do further processing with selectedRows here, e.g., updating state
    };

    return (
        <div style={{ height: 'calc(100vh - 120px)', overflow: 'auto', position: 'relative' }}>
            <div className={`ag-grid ${theme} custom-grid-theme`} style={gridStyle}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    rowDragManaged={true}
                    onRowDragEnd={onRowDragEnd}
                    defaultColDef={defaultColDef}
                    rowSelection={rowSelection}
                    onGridReady={onGridReady}
                    sideBar={true}
                    //
                    pivotPanelShow={"always"}
                    autoGroupColumnDef={autoGroupColumnDef}
                    animateRows={true}
                    onSelectionChanged={onSelectionChanged}
                    // enableFilter={true}
                    statusBar={statusBar}
                    rowHeight={40}
                    headerHeight={50}
                    defaultExcelExportParams={defaultExcelExportParams}
                    defaultCsvExportParams={defaultCsvExportParams}
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({ projectMarginReducer }) => {
    const isRawProjectSummeryListLoading = get(projectMarginReducer, 'isRawProjectSummeryListLoading', false);
    const rawProjectSummeryList = get(projectMarginReducer, 'rawProjectSummeryList', []);

    return {
        isRawProjectSummeryListLoading,
        rawProjectSummeryList,
    }
}

const mapDispatchToProps = {
    onFetchRawProjectSummeryList: fetchRawProjectSummeryList,
}

export default connect(mapStateToProps, mapDispatchToProps)(RawSummery);
