
import React from 'react';
import { GetTableConfigutaion, SaveLayouts } from "../../redux/FinanceMapping/saga";
export const cellFormatter = (params) => {
  return formatNumber(params.value);
};

const formatNumber = (number) => {
  //return Number(number).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return Math.floor(Number(number)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const cellFormatter1 = (params) => {
  return '';
};

const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
export const cellFormatterYear = (params) => {
  console.log("Formatted Value:", params);
  if (!params.value) return ""; // Handle cases where value might be null or undefined

  const value = parseInt(params.value.split('_')[0], 10); // Ensure value is parsed as an integer
  const month = value >= 1 && value <= 12 ? monthNames[value - 1] : params.value; // Map 1-12 to months

  console.log("Formatted Value:", value, month);
  return month;
};

export const processPivotResultColGroupDef = (colDef) => {
  if (colDef.pivotKeys) {
    // console.log('foeamted key', colDef)
    // Replace numeric keys (1-12) with month names, handling suffixes
    const formattedKeys = colDef.pivotKeys.map((key) => {
      // Split the key into numeric part and suffix
      const [numberPart, suffix] = key.toString().split('_');
      const numericValue = parseInt(numberPart, 10);

      // Map numeric values to month names
      const month = numericValue >= 1 && numericValue <= 12
        ? monthNames[numericValue - 1]
        : numberPart; // Fallback for non-month numeric values

      // Append suffix if present
      return suffix ? `${month}_${suffix}` : month;
    });

    // Join formatted keys for header display
    colDef.headerName = formattedKeys.join(' | '); // Customize delimiter if needed
  }
};

export const processPivotResultColGroupDefPeroid = (a, b) => {

  // Split into numeric and suffix parts
  const extractValueAndSuffix = (key) => {
    const [valuePart, suffix] = key.toString().split('_');
    return {
      numeric: parseInt(valuePart, 10),
      suffix: suffix || ''
    };
  };

  const aParts = extractValueAndSuffix(a);
  const bParts = extractValueAndSuffix(b);

  // Sort numerically first (descending)
  if (aParts.numeric !== bParts.numeric) {
    return bParts.numeric - aParts.numeric;
  }
  //console.log("Order value",aParts,bParts)
  // Sort alphabetically by suffix if numeric parts are equal (descending)
  return bParts.suffix.localeCompare(aParts.suffix);
}


export const processPivotResultColDefperiod1 = (colDef) => {
  //console.log('Process', colDef)

  if (colDef.pivotKeys) {
    // console.log('foeamted key', colDef)
    // Replace numeric keys (1-12) with month names, handling suffixes
    const formattedKeys = colDef.pivotKeys.map((key) => {
      // Split the key into numeric part and suffix
      const [numberPart, suffix] = key.toString().split('_');
      const numericValue = parseInt(numberPart, 10);

      // Map numeric values to month names
      const month = numericValue >= 1 && numericValue <= 12
        ? monthNames[numericValue - 1]
        : numberPart; // Fallback for non-month numeric values

      // Append suffix if present
      return suffix ? `${month}_${suffix}` : month;
    });

    // Join formatted keys for header display
    colDef.headerName = formattedKeys.join(' | '); // Customize delimiter if needed
  }
}
export const processPivotResultColDefperiod = (colDef) => {
  if (colDef.pivotKeys && colDef.pivotKeys.length > 2) {
    const key = colDef.pivotKeys[2]; // Access the key at index 3
    //console.log("Original Key at Index 3:", key);

    // Split the key into numeric part and suffix
    const [numberPart, suffix] = key.toString().split('_');

    // Convert the numeric part to a month if it is between 1 and 12
    const numericValue = parseInt(numberPart, 10);
    const month = numericValue >= 1 && numericValue <= 12
      ? monthNames[numericValue - 1] // Map 1-12 to month names
      : numberPart; // Fallback for non-month numeric values

    //console.log("Formatted Key at Index 3:", month);

    // Update the headerName with the formatted value
    colDef.headerName = suffix ? `${month}_${suffix}` : month;
  }
};


export const saveGridLayout = async (gridRef, currentTap, layouts, setLayoutSave) => {
  if (gridRef.current && gridRef.current.api) {
    console.log('before Columns:', gridRef.current.api.getColumnState());
    // Simplify column state
    const columnState = gridRef.current.api.getColumnState().map(({ colId, hide, aggFunc, pinned, width, pivot, enableRowGroup, rowGroup, enablePivot, enableValue }) => ({
      colId,
      hide,
      aggFunc,
      pinned,
      width,
      pivot,
      enableRowGroup,
      rowGroup,
      enablePivot,
      enableValue
    }));

    // Simplify row group state
    const rowGroupState = gridRef.current.api.getRowGroupColumns().map(({ colId }) => ({
      colId,
    }));

    // Simplify pivot state
    const pivotState = gridRef.current.api.getPivotColumns().map(({ colId }) => ({
      colId,
    }));

    // Simplify value state
    const valueState = gridRef.current.api.getValueColumns().map(({ colId, aggFunc, aggregationActive, visible, colDef, userProvidedColDef }) => ({
      colId,
      aggFunc, // Include aggregation function if relevant
      aggregationActive,
      visible,
      colDef,
      userProvidedColDef

    }));
    const filterModel = gridRef.current.api.getFilterModel(); // Get current filter state

    //console.log('After Setting Value Columns:', gridRef.current.api.getValueColumns());

    const savedState = {
      columnState,
      rowGroupState,
      pivotState,
      valueState,
      filterModel
    };
    if (!layouts)
      layouts = {}
    const user = JSON.parse(localStorage.getItem("user"));
    if (user.email) {

      const layoutdata = {
        Table_Name: currentTap,
        Layouts: savedState,
        User: user.email
      }
      // Store the cleaned state in localStorage
      //localStorage.setItem('gridLayout', JSON.stringify(savedState));
      const data = {
        key: 'Layouts',
        tableName: 'Grid_Layout',
        data: layoutdata
      }
      console.log('Grid layout saved:', data);
      await SaveLayouts(data)
      setLayoutSave(false)
    }
  }
};


export const restoreGridLayout = async (gridRef, currentTap,) => {
  if (gridRef.current && gridRef.current.api) {
    const layouts = await GetTableConfigutaion(currentTap)
    console.log('Saved Grid Layout:', layouts);
    if (layouts && layouts['Layouts']) {

      const savedState = layouts['Layouts']
      //console.log('Saved Grid Layout:', savedState);
      if (savedState && gridRef.current.api) {
        // gridRef.current.api.setPivotMode(true);
        const { columnState, rowGroupState, pivotState, valueState, filterModel } = savedState;

        // Debug: Check if the saved state matches the grid columns
        // console.log('Saved Column State:', columnState);
        // console.log('Saved Row Group State:', rowGroupState);
        // console.log('Saved Pivot State:', pivotState);
        // console.log('Saved Value State:', valueState);

        // Apply column state
        const columnStateApplied = gridRef.current.api.applyColumnState({
          state: columnState,
          applyOrder: true, // Ensure column order is restored
        });

        // Debug: Check if column state was successfully applied
        console.log('Column State Applied:', columnStateApplied);

        // Apply row group, pivot, and value columns (if column state applied successfully)
        if (columnStateApplied) {
          if (rowGroupState && rowGroupState.length) {
            gridRef.current.api.setRowGroupColumns(rowGroupState.map(({ colId }) => colId));
          }

          if (pivotState && pivotState.length) {
            gridRef.current.api.setPivotColumns(pivotState.map(({ colId }) => colId));
          }
          if (filterModel) {
            var keys = Object.keys(filterModel);
             if(keys && keys.length > 0)
             gridRef.current.api.setFilterModel(filterModel)
          }

          // if (valueState && valueState.length) {
          //     gridRef.current.api.setValueColumns(
          //         valueState.map(({ colId, aggFunc }) => ({
          //             colId :colId,
          //             aggFunc: aggFunc || null, // Provide a fallback for aggregation functions
          //         }))
          //     );
          // }
          // console.log('valueState Value Columns:', valueState.length);
          if (valueState && valueState.length) {

            const validValueState = valueState
              .filter(({ colId }) => colId) // Ensure colId exists
              .map(({ colId, aggFunc, aggregationActive, visible, colDef, userProvidedColDef }) => ({
                colId,
                aggFunc: aggFunc || 'sum', // Default to null if aggFunc is missing
                aggregationActive: aggregationActive,
                visible,
                colDef,
                userProvidedColDef
              }));

            console.log('Setting Value Columns:', validValueState);

            gridRef.current.api.addValueColumns(validValueState);
            gridRef.current.api.refreshClientSideRowModel('aggregate');

            // console.log('Value columns set and row model refreshed.');
            // console.log('Is Pivot Mode Enabled:', gridRef.current.api.isPivotMode());
          }

          gridRef.current.api.hideOverlay();

          console.log('Grid layout restored successfully.');
        } else {
          console.error('Failed to apply column state.');
        }
      } else {
        console.log('No saved layout found in localStorage.');
      }
    }
    else {
      console.log('No saved layout found in Database.');
    }
  }
};
export const CustomLoadingCellRenderer = ({ loadingMessage }) => {
  return (
    <div style={{ textAlign: 'center', padding: '10px', color: '#888' }}>
      <div className="spinner" style={{ marginBottom: '8px' }}>
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
      <span>{loadingMessage}</span>
    </div>
  );
};

