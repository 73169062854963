import React, { useState, useEffect } from 'react';

import { Card, Row, Col, Typography, Modal } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import { Welding_Link, Coating_PIH_Link, Inspection_Link } from '../config.js'
//import GenerateLicenseForm from '../components/LicenseForm.js';
import history from '../services/history';
import { authenticateCurrentUser } from '../services/auth.js';
const { Title, Text } = Typography;
const HomePage = (props) => {


  const [userName, setUserName] = useState('');
  const [userGroup, setUserGroup] = useState([]);
  /** get user Access */
  useEffect(() => {
    authenticateCurrentUser()
      .then(async (data) => {
        if (data.signInUserSession.accessToken.payload["cognito:groups"]) {

          let response = data.signInUserSession.accessToken.payload["cognito:groups"] //await getA(data.username)
          //const userAccess = response.map(item => item?.GroupName)
          //setUserName(data.username);
          // console.log(response)
          setUserGroup(response);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [history]);

  const feildOperation = [{
    id: 1,
    name: "Welding",
    value: Welding_Link
  },
  {
    id: 2,
    name: "Coating",
    value: Coating_PIH_Link
  },
  // {
  //   id: 3,
  //   name: "Inpection",
  //   value: Inspection_Link
  // }
  ]
  const groups = localStorage.getItem('group');


  const assets = [{ id: 1, name: 'Maintentance', value: "" }, { id: 2, name: 'Tracking', value: "" }]
  const qualityAndSafity = [{ id: 1, name: 'Cards-1', value: "" }, { id: 2, name: 'Cards-2', value: "" }]

  const onfeildOperationClicked = (item) => {
    //console.log(item)
    //setSelectedItem(item.name);
    sessionStorage.setItem('selectedFeilOperation', true)
    window.location.replace(item.value)
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="flex-auto" >
        <div className="d-flex mb-3 ps-3 align-items-center tab-content-title" style={{ "margin-top": 10 }}>
          <Typography className="project-title" level={4}>
            Field Operation
          </Typography>
        </div>
        <div className="custom-line" />
        <div >
          <Row gutter={24} className="detail-rows">
            {feildOperation.map((item) => (
              <Col span={40}>
                <Card className='custom-card'
                  style={{ background: item.value ? '#ef314c' : "gray", borderColor: item.value ? '#ef314c' : "gray" }}
                  onClick={() => item.value ? onfeildOperationClicked(item) : ""}
                >


                  <div style={{ marginTop: '-10px' }} >
                    <div >
                      {item.name}
                    </div>
                    <div style={{ paddingTop: '5px' }}>
                      <RightCircleOutlined></RightCircleOutlined>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        {/* <div className="d-flex mb-3 ps-3 align-items-center tab-content-title" style={{ "margin-top": 20 }}>
          <Typography className="project-title" level={4}>
            Quality And Safety
          </Typography>
        </div>
        <div className="custom-line" /> */}
        {/* <div >
          <Row gutter={24} className="detail-rows">
            {qualityAndSafity.map((item) => (
              <Col span={30}>
                <Card className='custom-card'
                  style={{ background: item.value ? '#ef314c' : "gray", borderColor: item.value ? '#ef314c' : "gray" }}>
                  {item.name}
                </Card>
              </Col>
            ))}
          </Row>
        </div> */}
        {userGroup && userGroup.includes('spare-parts-normal-user') || userGroup.includes('calibration-manager') || userGroup.includes('license-manager') || userGroup.includes('utilization-normal-user') ?
          <div>
            <div className="d-flex mb-3 ps-3 align-items-center tab-content-title" style={{ "margin-top": 20 }}>
              <Typography className="project-title" level={4}>
                Assets
              </Typography>
            </div>
            <div className="custom-line" />
            <div >
              <Row gutter={24} className="detail-rows">
                {/* {assets.map((item) => (
              <Col span={30}>
                <Card className='custom-card'
                  //onClick={() => setGenerateLiceseVisible(true)}
                  style={{ background: item.value ? '#ef314c' : "gray", borderColor: item.value ? '#ef314c' : "gray" }}>
                  {item.name}

                </Card>
              </Col>

            ))} */}
                {
                  userGroup && userGroup.includes('spare-parts-normal-user') &&
                  (
                    <Col span={40}>

                      <Card className='custom-card'
                        onClick={() => { history.push('/SparePartsGenerator') }}
                        style={{ background: '#ef314c', borderColor: '#ef314c' }}>


                        <div style={{ marginTop: '-12px' }} >
                          <div >
                            <Text style={{ display: 'table-caption', position: 'absolute' }}>{'Spare Parts Generator'}</Text>
                          </div>
                          <div style={{ paddingTop: '30px' }}>
                            <RightCircleOutlined></RightCircleOutlined>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  )
                }
                {userGroup && userGroup.includes('calibration-manager') &&
                  (
                    <Col span={40}>

                      <Card className='custom-card'
                        onClick={() => {
                          history.push('/calibration');
                        }}
                        style={{ background: '#ef314c', borderColor: '#ef314c' }}>

                        <div style={{ marginTop: '-10px' }} >
                          <div >
                            {"Calibration"}
                          </div>
                          <div style={{ paddingTop: '5px' }}>
                            <RightCircleOutlined></RightCircleOutlined>
                          </div>
                        </div>

                      </Card>
                    </Col>)
                }

                {
                  userGroup && userGroup.includes('license-manager') && (
                    <Col span={40}>
                      <Card className='custom-card'
                        onClick={() => {
                          history.push('/generate-license');
                        }}
                        style={{ background: '#ef314c', borderColor: '#ef314c' }}>

                        <div style={{ marginTop: '-10px' }} >
                          <div >
                            {"License Manager"}
                          </div>
                          <div style={{ paddingTop: '5px' }}>
                            <RightCircleOutlined></RightCircleOutlined>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  )
                }
                {
                  userGroup && userGroup.includes('utilization-normal-user') &&
                  (
                    <Col span={40}>

                      <Card className='custom-card'
                        onClick={() => { history.push('/Utilization') }}
                        style={{ background: '#ef314c', borderColor: '#ef314c' }}>


                        <div style={{ marginTop: '-10px' }} >
                          <div >
                            <Text>{'Utilization'}</Text>
                          </div>
                          <div style={{ paddingTop: '5px' }}>
                            <RightCircleOutlined></RightCircleOutlined>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  )
                }

                {/* {
                  userGroup && userGroup.includes('TrainingManagement') &&
                  (
                    <Col span={40}>
                      <a
                        type="link"
                        onClick={() => {
                          history.push('/TrainingManagement');
                        }}
                      >
                        <Card className='custom-card'
                          //onClick={() => setSparePartsGeneratorVisible(true)}
                          style={{ background: '#ef314c', borderColor: '#ef314c', height: '117px' }}>

                          <Text style={{ display: 'table-caption', position: 'absolute' }}>{'Training Management'}</Text>
                        </Card>
                      </a>
                    </Col>
                  )
                } */}
              </Row>
            </div>
          </div> : null
        }
        {userGroup && userGroup.includes('track-record-normal-user') || userGroup.includes('pricing-normal-user') ||  userGroup.includes('finance-mapping-admin' )?
          <div>
            <div className="d-flex mb-3 ps-3 align-items-center tab-content-title" style={{ "margin-top": 20 }}>
              <Typography className="project-title" level={4}>
                Sales
              </Typography>
            </div>
            <div className="custom-line" />
            <div >
              <Row gutter={24} className="detail-rows">
                {
                  userGroup && userGroup.includes('track-record-normal-user') &&
                  (
                    <Col span={40}>
                      <Card className='custom-card'
                        onClick={() => {
                          history.push('/TrackRecord');
                        }}
                        style={{ background: '#ef314c', borderColor: '#ef314c' }}>

                        <div style={{ marginTop: '-10px' }} >
                          <div >
                            {"Track Record"}
                          </div>
                          <div style={{ paddingTop: '5px' }}>
                            <RightCircleOutlined></RightCircleOutlined>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  )
                }

                {
                  userGroup && userGroup.includes('pricing-normal-user') &&
                  (
                    <Col span={40}>
                      <Card className='custom-card'
                        onClick={() => {
                          history.push('/Pricing');
                        }}
                        style={{ background: '#ef314c', borderColor: '#ef314c' }}>

                        <div style={{ marginTop: '-10px' }} >
                          <div >
                            {"Pricing"}
                          </div>
                          <div style={{ paddingTop: '5px' }}>
                            <RightCircleOutlined></RightCircleOutlined>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  )
                }
                {
                  userGroup && userGroup.includes('pricing-gudiance-normal-user') &&
                  (
                    <Col span={40}>
                      <Card className='custom-card'
                        onClick={() => {
                          history.push('/Data360Pricing');
                        }}
                        style={{ background: '#ef314c', borderColor: '#ef314c' }}>

                        <div style={{ marginTop: '-10px' }} >
                          <div >
                            {"Pricing Gudiance"}
                          </div>
                          <div style={{ paddingTop: '5px' }}>
                            <RightCircleOutlined></RightCircleOutlined>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  )
                }


                {
                  userGroup && userGroup.includes('pricing-normal-user') &&
                  (
                    <Col span={40}>
                      <Card className='custom-card'
                        onClick={() => {
                          history.push('/welding-wire');
                        }}
                        style={{ background: '#ef314c', borderColor: '#ef314c' }}>

                        <div style={{ marginTop: '-10px' }} >
                          <div >
                            {"Welding Wire Calculator"}
                          </div>
                          <div style={{ paddingTop: '5px' }}>
                            <RightCircleOutlined></RightCircleOutlined>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  )
                }
              </Row>
            </div>
          </div> : null
        }
        {userGroup && userGroup.includes('track-record-normal-user') || userGroup.includes('pricing-normal-user') ||  userGroup.includes('finance-mapping-admin' )?
          <div>
            <div className="d-flex mb-3 ps-3 align-items-center tab-content-title" style={{ "margin-top": 20 }}>
              <Typography className="project-title" level={4}>
                Finance
              </Typography>
            </div>
            <div className="custom-line" />
            <div >
              <Row gutter={24} className="detail-rows">
                {
                  userGroup && userGroup.includes('finance-mapping-admin') &&
                  (
                    <Col span={40}>
                      <Card className='custom-card'
                        onClick={() => {
                          history.push('/FinanceMapping');
                        }}
                        style={{ background: '#ef314c', borderColor: '#ef314c' }}>

                        <div style={{ marginTop: '-10px' }} >
                          <div >
                            {"Finance"}
                          </div>
                          <div style={{ paddingTop: '5px' }}>
                            <RightCircleOutlined></RightCircleOutlined>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  )
                }
              </Row>
            </div>
          </div> : null
        }
      </div>

    </div>
  );
}


const mapStateToProps = ({

}) => {
  return {

  };
};

const mapDispatchToProps = {
};


export default (HomePage);
