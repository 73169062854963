import SaparepartsUpdateForm from '../components/Spare Parts generator/SaparepartsUpdateForm';
import { HomeLayout } from '../layout';
import Calibration from '../pages/Calibration';
import GenerateLicense from '../pages/GenerateLicense';
import HomePage from '../pages/HomePage';
import SignOutPage from '../pages/SignOutPage';
import SparePartsgenerator from '../pages/SparePartsgenerator';
import TrainingManagement from '../components/Training Management/TrainingManagement';
import AdminPage from '../pages/admin';
import TrackRecordPage from '../components/TrackRecord/TrackRecordPage';
import PricingPage from '../components/Pricing Management/PricingPage';
import WeldingWireCalculator from '../pages/WeldingWireCalculator';
import Data360Page from '../components/Data360 Mangement/Data360Page';
import FinanceMapping from '../components/FinanceMapping/FinanceMapping';
import CardTabs from '../components/FinanceTabs/CardTabs';
import ProjectMarginCards from '../components/FinanceTabs/ProjectMarginCards';
import ProjectConsoleCards from '../components/FinanceTabs/ProjectConsoleCards';
import TieOutTabs from '../components/FinanceTabs/TieOutTabs';
import RawCards from '../components/FinanceTabs/rawFile/RawCards';
import GeneralTabs from '../components/FinanceTabs/general/GeneralTabs';
import WeekelyReportingTabs from '../components/FinanceTabs/weekelyReport/WeekelyReportingTabs';
import MeReportingTabs from '../components/FinanceTabs/meReport/MeReportingTabs';
import Checks3Tabs from '../components/FinanceTabs/checks3/Checks3Tabs';
import Checks2CommercialTabs from '../components/FinanceTabs/check2commercial/Checks2CommercialTabs';
import Checks1Tabs from '../components/FinanceTabs/check1/Checks1Tabs';
import BrazilTabs from '../components/FinanceTabs/brazil/BrazilTabs';
import SupplementalTabs from '../components/FinanceTabs/supplemental/SupplementalTabs';
import UtilizationPageAgGrid from '../components/Utilization/UtilizationPageAgGrid';

//import SparePartsgenerator from '../pages/SparepartsUpdateForm';
export default [
  {
    path: '/',
    exact: true,
    layout: HomeLayout,
    component: HomePage,
  },

  {
    path: '/SmartLync-HomePage',
    layout: HomeLayout,
    component: HomePage,
  },
  {
    path: '/SmartLync-SignOutPage',
    layout: HomeLayout,
    component: SignOutPage,
  },
  // {
  //   path: '/Generate-License',
  //   layout: HomeLayout,
  //   component: GenerateLicenseForm,
  // },
  {
    path: '/generate-license',
    layout: HomeLayout,
    component: GenerateLicense,
  },
  {
    path: '/SparePartsGenerator',
    layout: HomeLayout,
    component: SparePartsgenerator,
  },
  {
    path: '/SparePartsUpdate',
    layout: HomeLayout,
    component: SaparepartsUpdateForm,
  },
  {
    path: '/TrainingManagement',
    layout: HomeLayout,
    component: TrainingManagement,
  },
  {
    path: '/TrackRecord',
    layout: HomeLayout,
    component:TrackRecordPage ,
  },
  {
    path: '/Pricing',
    layout: HomeLayout,
    component:PricingPage ,
  },
  {
    path: '/Data360Pricing',
    layout: HomeLayout,
    component:Data360Page ,
  },
  {
    path: '/calibration',
    layout: HomeLayout,
    component: Calibration,
  },
  {
    path: '/admin',
    layout: HomeLayout,
    component: AdminPage,
  },
  {
    path: '/welding-wire',
    layout: HomeLayout,
    component: WeldingWireCalculator,
  },
  {
    path: '/FinanceMapping',
    layout: HomeLayout,
    component: FinanceMapping,
  },
  {
    path: '/raw-data',
    layout: HomeLayout,
    component: RawCards,
  },
  {
    path: '/general-data',
    layout: HomeLayout,
    component: GeneralTabs,
  },
  {
    path: '/weekly-reporting',
    layout: HomeLayout,
    component: WeekelyReportingTabs,
  },
  {
    path: '/me-reporting',
    layout: HomeLayout,
    component: MeReportingTabs,
  },
  {
    path: '/checks3',
    layout: HomeLayout,
    component: Checks3Tabs,
  },
  {
    path: '/check2-commercial',
    layout: HomeLayout,
    component: Checks2CommercialTabs,
  },
  {
    path: '/check1',
    layout: HomeLayout,
    component: Checks1Tabs,
  },
  {
    path: '/brazil',
    layout: HomeLayout,
    component: BrazilTabs,
  },
  {
    path: '/supplement',
    layout: HomeLayout,
    component: SupplementalTabs,
  },
  {
    path: '/project-margin',
    layout: HomeLayout,
    component: ProjectMarginCards,
  },
  {
    path: '/Utilization',
    layout: HomeLayout,
    component: UtilizationPageAgGrid,
  },

];
