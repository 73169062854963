/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
    Button, Form,
    Input,
    Modal,
    Space,
    Table,
    Tabs,
    Tooltip,
    Typography,
    Upload, notification
} from 'antd';
import ExcelJS from 'exceljs';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import { deleteMasterList, fetchSparePartsMasterList, fetchSparePartsMasterListPage, uploadMsterSparePartsAction } from '../../redux/Spareparts/action.js';
import history from '../../services/history.js';
import MasterAddnew from './MasterAddnew.js';

const { Title, Text } = Typography;
//const { TabPane } = Tabs;
//const { Column } = Table;
//const { Search } = Input;

//const { Option } = Select;
const MasterUploadForm = (props) => {

    const {
        sparePartsMasterData,
        isSpartPartsMasterLoading,
        sparePartsMasterListCount,
        setShowMasterModel,
        databaseMasterList,
        isDatabaseMasterLoading,
        databaseMasterListCount,

        showMasterModel,

        onFetchSparePartsMasterData,
        onUploaMasterSpareparts,
        onFetchDatabaseList,
        OnDeleteMasterList,
        isDeleteLoading
    } = props;

    const dataSource = [
        {
            key: '1',
            partNumber: 'C235418',
            description: 'HOLDER,TOOL PIGGY-BACK   5 DEG, 110 OFFS',
            quantity_5000_Welds: 1,
            category: 'BEVELLING',
            multiple_item: 'Yes',
            cost: '23',
            where_Used: 'BD4 BEV-CBUC-HW',
            UOM: 'EA',
            alternate_Parts: 'F2M0044-R',
            // Add other properties as needed
        },
    ]

    const [searchText, setSearchText] = useState({});
    const [searchedColumn, setSearchedColumn] = useState('');
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [currentPagesize, setCurrentPagesize] = useState(10);

    const [masterUploadForm] = Form.useForm()
    const [addNewForm] = Form.useForm()
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [bulkUplaodModal, setBulkUplaodModal] = useState(false);
    const [databaseList, setDatabaseList] = useState([]);
    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editRecord, seteditRecord] = useState('');
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');

    const columns = [
        {
            title: 'Part Number',
            dataIndex: 'partNumber',
            key: 'partNumber',
            align: 'center',

        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            align: 'center',
            ellipsis: {
                showTitle: true
            },

            // render: (value) => {
            //     return <span>{capitalizeWords(value)}</span>;
            // }
        },
        {
            title: 'Quantity/5000 Welds',
            dataIndex: 'quantity_5000_Welds',
            key: 'quantity_5000_Welds',
            align: 'center',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            align: 'center'
        },
        {
            title: 'Multiple Item',
            dataIndex: 'multiply_Item',
            key: 'multiply_Item',
            align: 'center'
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            key: 'cost',
            align: 'center'
        },
        {
            title: 'Where Used',
            dataIndex: 'where_Used',
            key: 'where_Used',
            align: 'center'
        },
        {
            title: 'UOM',
            dataIndex: 'UOM',
            key: 'UOM',
            align: 'center'
        },
        {
            title: 'Alternate Parts',
            dataIndex: 'alternate_Parts',
            key: 'alternate_Parts',
            align: 'center'
        },
        {
            title: 'Actions',
            key: 'action',
            dataIndex: 'action',
            render: (text, record) => {
                return (
                    <div>
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px' }} onClick={() => onUpdateClick(record)} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                <DeleteOutlined style={{ fontSize: '18px' }} onClick={() => showDeleteModal(record)} />
                            </span>
                        </Tooltip>
                    </div>
                );
            },
            align: 'center'
        }
    ];

    const onUpdateClick = (record) => {
        // console.log(record)
        if (record) {

            const editData = {
                'partNumber': record.partNumber,
                'description': record.description,
                'quantity_5000_Welds': record.quantity_5000_Welds,
                'category': record.category,
                'multiply_Item': record.multiply_Item,
                'cost': record.cost,
                'where_Used': record.where_Used,
                'UOM': record.UOM,
                'alternate_Parts': record.alternate_Parts,
            }
            addNewForm.setFieldsValue(editData)

            seteditRecord(record)
            setIsEdit(true)
            setShowAddNewModal(true)
        }

    }
    // const onDeleteClick = async (record) => {
    //     //console.log(record)
    //     if (record) {
    //         await OnDeleteMasterList({ data: record })
    //     }

    // }

    /** Display Confirmation while deleting */
    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedData(record)
    };
    /** Deleting master Spare parts data on confirm */
    const handleDeleteOk = async () => {
        if (selectedData)
            await OnDeleteMasterList({ data: selectedData })

    }
    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedData('');
    }
    /** Close modal & delete values after confirmation equipment details */
    useEffect(() => {
        if (!isDeleteLoading) {
            setIsDeleteModalVisible(false);
            setSelectedData('');
        }
    }, [isDeleteLoading]);
    useEffect(() => {
        if (!showAddNewModal)
            addNewForm.resetFields();
    }, [showAddNewModal])
    useEffect(() => {
        var obj = {
            pageSize: currentPagesize,
            pageNumber: currentPageNumber,
        }
        onFetchSparePartsMasterData(obj);

    }, [])
    useEffect(() => {
        if (databaseMasterList && databaseMasterList.length > 0)
            setDatabaseList([...databaseMasterList])
        else {
            setDatabaseList([])
        }

    }, [databaseMasterList])
    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setDisableFileUpload(false);
        },
        beforeUpload: (file) => {

            setFileList([file]);
            setDisableFileUpload(true);
            return false;
        },
        fileList,
    };
    const handleCancelUploadFile = () => {
        setBulkUplaodModal(false);
    }

    useEffect(() => {

        if (databaseList && databaseList.length > 0) {
            //console.log('Master Databse List', databaseList)
            const workbook = new ExcelJS.Workbook();
            const masterstationSheet = workbook.addWorksheet('Spare parts');
            const headerRow = masterstationSheet.getRow(1);
            headerRow.height = 20;



            masterstationSheet.columns = [
                { header: 'ID', key: 'ID', width: 5 },
                { header: 'Part Number', key: 'PART NUMBER', width: 10 },
                { header: 'Description', key: 'DESCRIPTION', width: 20 },
                { header: 'Qty/5000 Welds', key: 'QTY/5000 WELDS', width: 20 },
                { header: 'Category', key: 'CATEGORY', width: 20 },
                { header: 'Multiply Item', key: 'MULTIPLY ITEM', width: 20 },
                { header: 'Cost', key: 'COST', width: 20 },
                { header: 'Where Used', key: 'WHERE USED', width: 20 },
                { header: 'UOM', key: 'UOM', width: 20 },
                { header: 'Alternate Parts', key: 'ALTERNATE PARTS', width: 20 },
            ];

            headerRow.eachCell((cell) => {
                cell.font = { bold: true };
            });

            (async () => {

                const ws = workbook.getWorksheet('Spare parts');

                // Add data to the worksheet
                ws.addRows(databaseList);

                // Convert to buffer
                workbook.xlsx.writeBuffer().then((buffer) => {
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'Master_Spare_Parts_List.xlsx';
                    link.click();
                });
            })();

        }
        setDatabaseList([])

    }, [databaseList])
    const handleDownloadTemplate = async () => {

        await onFetchDatabaseList()
    };
    useEffect(() => {

        if (confirmLoading && !isDatabaseMasterLoading) {
            setBulkUplaodModal(false)
        }
    }, [isDatabaseMasterLoading])
    const ExcelDateToJSDate = (date) => {
        const convertedDate = new Date(Math.round((date - 25569) * 864e5));
        return String(convertedDate).slice(4, 15);
    };
    const handleUploadFile5 = async () => {
        if (fileList.length > 0) {
            const file = fileList[0];

            if (file.type === 'text/csv') {
                const reader = new FileReader();

                reader.onload = async (event) => {
                    try {
                        const data = event.target.result;
                        const workbook = XLSX.read(data, { type: 'string' });

                        const sheetName = workbook.SheetNames[0];
                        const sheet = workbook.Sheets[sheetName];

                        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                        if (jsonData[1].length === 0) {
                            notification.error({ message: 'Invalid file. Please select a valid file.' });
                            return;
                        }

                        const headers = jsonData[0];
                        const S3_BUCKET_NAME = 'pih-heatprofile-report-prod'
                       

                        const formattedData = jsonData.slice(1).map(row => {
                            //  const rowData = [...row];
                            const rowData = {};
                            headers.forEach((header, index) => {
                                const value = row[index];
                                if (value && value != '') {
                                    if (header === 'pdfReportDetails' || header == 'textFileDetails' || header == 'imageFileDetails') {


                                        try {
                                            const jsonValue = JSON.parse(value);
                                            const s3Key = jsonValue.s3Key.split('/')[1];
                                            // const s3Url = updateS3Url(jsonValue);
                                            const fileName = jsonValue.fileName;

                                            rowData[header] =
                                            {
                                                s3Key: s3Key,
                                                s3Url: `https://${S3_BUCKET_NAME}.s3.us-west-2.amazonaws.com/${s3Key}`,
                                                fileName: fileName
                                            }

                                            // let urlParts = url.split('/');
                                            // urlParts[2] = urlParts[2].replace('ap-southeast-2', 'us-west-2');
                                            // urlParts[3] = urlParts[3].replace('prod', '');
                                            // return urlParts.join('/');
                                            //JSON.stringify(s3Url)
                                            // rowData[index] = JSON.stringify({
                                            //     s3Key,
                                            //     s3Url,
                                            //     fileName
                                            // });
                                        } catch (e) {
                                            console.log('Error parsing JSON:', e);
                                        }
                                    }
                                    //     value = value.trim()
                                    else if (header === 'trailDate' || header === 'updatedAt' || header === 'createdAt') {

                                        const formattedDate = moment(ExcelDateToJSDate(value), "DD MM YYYY").format("YYYY-MM-DD");
                                        if (!formattedDate.includes('Invalid date'))
                                            rowData[header] = formattedDate
                                        else {
                                            const formattedDate = moment(value, "M-D-YY").format("YYYY-MM-DD");
                                            if (!formattedDate.includes('Invalid date'))
                                                rowData[header] = formattedDate
                                            else {
                                                const formattedDate = moment(value, "DD-MM-YY").format("YYYY-MM-DD");
                                                if (!formattedDate.includes('Invalid date'))
                                                    rowData[header] = formattedDate
                                                else {
                                                    const formattedDate = moment(ExcelDateToJSDate(value), "MMM DD YYYY").format("YYYY-MM-DD");
                                                    rowData[header] = formattedDate
                                                }

                                            }
                                        }
                                    }
                                    else {
                                        rowData[header] = value
                                    }
                                }

                            });
                            return rowData;
                        });
                        console.log(formattedData)
                        return;
                        // Create a new workbook with the updated data
                        const newWorkbook = XLSX.utils.book_new();
                        const newSheet = XLSX.utils.aoa_to_sheet([headers, ...formattedData]);
                        XLSX.utils.book_append_sheet(newWorkbook, newSheet, sheetName);

                        // Generate CSV file and trigger download
                        const csv = XLSX.utils.sheet_to_csv(newSheet);
                        const blob = new Blob([csv], { type: 'text/csv' });
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = 'Updated heat profile.csv';
                        link.click();

                        notification.success({ message: 'File processed and saved successfully!' });
                    } catch (error) {
                        notification.error({ message: 'Error parsing CSV file. Please select a valid file.' });
                    }
                };

                reader.readAsText(file);
            } else {
                notification.warn({ message: 'Please select a valid CSV file to upload.' });
            }
        } else {
            notification.warn({ message: 'Please select a file to upload.' });
        }
    };

    const handleTrackertLsitUploadFile2 = async () => {
        if (fileList.length > 0) {
            const file = fileList[0];

            if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const reader = new FileReader();

                reader.onload = async (event) => {
                    try {
                        const data = new Uint8Array(event.target.result);
                        const workbook = XLSX.read(data, { type: 'array' });

                        const sheetName = workbook.SheetNames[0];
                        const sheet = workbook.Sheets[sheetName];

                        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                        if (jsonData[1].length === 0) {
                            notification.error({ message: 'Invalid file. Please select a valid file.' });
                            return;
                            //throw new Error('Invalid file. Please select a valid file.');
                        }
                        console.log(jsonData)
                        //const headers = jsonData[0];

                        const keyMapping = {
                            'Vendor': 'Vendor',
                            'Project Name': 'ProjectName',
                            'File Name': 'FileName',
                            'Test Type': 'TestType',
                            'Timeline': 'Timeline',
                            'Temperature': 'Temperature',
                            'Material 1': 'Material_1',
                            'Material 2': 'Material_2',
                            'Material 3': 'Material_3',
                            'Material 4': 'Material_4',
                            'Material 5': 'Material_5',
                            'Batch 1': 'Batch_1',
                            'Batch 2': 'Batch_2',
                            'Batch 3': 'Batch_3',
                            'Batch 4': 'Batch_4',
                            'Batch 5': 'Batch_5',
                            'Contractor': 'Contractor',
                            'Company': 'Company',
                            'Report Number': 'ReportNumber',
                            'Purchase Order': 'PurchaseOrder',
                            'Date Tested': 'TestedDate',
                            'Date Reported': 'ReportedDate'

                        };


                        const headers = jsonData[0];
                        console.log(headers)
                        // const allHeadersAvailable = Object.keys(keyMapping)
                        // .filter(key => key !== 'ID') // Exclude 'ID' key
                        // .every(header => headers.includes(header));
                        // console.log(allHeadersAvailable);

                        // if (!allHeadersAvailable) {
                        //     notification.error({ message: 'Invalid header file. Please select a valid file.' });
                        //     return;
                        //     //throw new Error('Invalid file. Please select a valid file.');
                        // }
                        const formattedData = jsonData.slice(1).map(row => {
                            const rowData = {};
                            let results = {}
                            headers.forEach((header, index) => {
                                const mappedKey = keyMapping[header]
                                let value = row[index];
                                // if (value !== undefined && value != '')
                                //     value = value.trim()
                                
                              // if (header !== undefined)
                                {
                                    rowData[header] = value !== undefined ? value : ''; // Set value to empty string if undefined
                                }
                               
                            });
                            return rowData;
                        });
                        formattedData.reverse();
                        console.log(formattedData)
                        //setConfirmLoading(true)
                        //await onUploaMasterSpareparts({ data: formattedData })
                        // Send the formatted data to your API

                        //setTimeout(UpdateUplaodmastrList, 1000)


                    } catch (error) {
                        notification.error({ message: 'Error parsing Excel file. Please select a valid file.' });
                    }
                };

                reader.readAsArrayBuffer(file);
            } else {
                notification.warn({ message: 'Please select a valid Excel file (XLS or XLSX) to upload.' });
            }
        } else {
            notification.warn({ message: 'Please select a file to upload.' });
        }
    };

    const handleUploadFile2 = async () => {
        if (fileList.length > 0) {
            const file = fileList[0];

            if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const reader = new FileReader();

                reader.onload = async (event) => {
                    try {
                        const data = new Uint8Array(event.target.result);
                        const workbook = XLSX.read(data, { type: 'array' });

                        const sheetName = workbook.SheetNames[0];
                        const sheet = workbook.Sheets[sheetName];

                        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                        if (jsonData[1].length === 0) {
                            notification.error({ message: 'Invalid file. Please select a valid file.' });
                            return;
                            //throw new Error('Invalid file. Please select a valid file.');
                        }
                        console.log(jsonData)
                        //const headers = jsonData[0];

                        const keyMapping = {
                           // 'Vendor': 'Vendor',
                            'Project Name': 'ProjectName',
                            'File Name': 'FileName',
                            // 'Test Type': 'TestType',
                            // 'Timeline': 'Timeline',
                            // 'Temperature': 'Temperature',
                            // 'Material 1': 'Material_1',
                            // 'Material 2': 'Material_2',
                            // 'Material 3': 'Material_3',
                            // 'Material 4': 'Material_4',
                            // 'Material 5': 'Material_5',
                            // 'Batch 1': 'Batch_1',
                            // 'Batch 2': 'Batch_2',
                            // 'Batch 3': 'Batch_3',
                            // 'Batch 4': 'Batch_4',
                            // 'Batch 5': 'Batch_5',
                            // 'Contractor': 'Contractor',
                            // 'Company': 'Company',
                            // 'Report Number': 'ReportNumber',
                            // 'Purchase Order': 'PurchaseOrder',
                            // 'Date Tested': 'TestedDate',
                            // 'Date Reported': 'ReportedDate'

                        };


                        const headers = jsonData[0];
                        console.log(headers)
                        // const allHeadersAvailable = Object.keys(keyMapping)
                        // .filter(key => key !== 'ID') // Exclude 'ID' key
                        // .every(header => headers.includes(header));
                        // console.log(allHeadersAvailable);

                        // if (!allHeadersAvailable) {
                        //     notification.error({ message: 'Invalid header file. Please select a valid file.' });
                        //     return;
                        //     //throw new Error('Invalid file. Please select a valid file.');
                        // }
                        const formattedData = jsonData.slice(1).map((row,index) => {
                            const rowData = {};
                            let results = {}
                            rowData['ID'] = 2404+index + 1
                            headers.forEach((header, index) => {
                                const mappedKey = keyMapping[header]
                                let value = row[index];
                                // if (value !== undefined && value != '')
                                //     value = value.trim()
                                if (header === 'Date Tested' || header === 'Date Reported') {

                                    const formattedDate = moment(ExcelDateToJSDate(value), "DD MM YYYY").format("YYYY-MM-DD");
                                    if (!formattedDate.includes('Invalid date'))
                                        rowData[mappedKey] = formattedDate
                                    else {
                                        const formattedDate = moment(value, "M-D-YY").format("YYYY-MM-DD");
                                        if (!formattedDate.includes('Invalid date'))
                                            rowData[mappedKey] = formattedDate
                                        else {
                                            const formattedDate = moment(value, "DD-MM-YY").format("YYYY-MM-DD");
                                            if (!formattedDate.includes('Invalid date'))
                                                rowData[mappedKey] = formattedDate
                                            else {
                                                const formattedDate = moment(ExcelDateToJSDate(value), "MMM DD YYYY").format("YYYY-MM-DD");
                                                rowData[mappedKey] = formattedDate
                                            }

                                        }
                                    }
                                }
                                else if (mappedKey !== undefined) {
                                    rowData[mappedKey] = value !== undefined ? value : '_'; // Set value to empty string if undefined
                                }
                                else if (header !== "Material Found" && header !== "Issue #" && header !== 'No of Failure') {
                                    // Handle nested data under 'Result'
                                    if (!rowData['Results']) {
                                        rowData['Results'] = {}; // Initialize 'Result' object if not exist
                                    }
                                    if (header === 'Result') {
                                        try {
                                            let v = value === undefined || value === 'NaN ' ? '_' : value;
                                            if (v !== '_' && v !== '' && v !== ' ') {
                                                const jsonobject = JSON.parse(v)
                                                if (jsonobject)
                                                    value = jsonobject
                                            }
                                        }
                                        catch (error) {
                                            console.log(error + row)
                                        }

                                    }
                                    if (value !== undefined)
                                        rowData['Results'][header] = value === 'NaN ' ? '_' : value;


                                }
                            });
                            return rowData;
                        });
                        formattedData.reverse();
                        console.log(formattedData)
                        //setConfirmLoading(true)
                        //await onUploaMasterSpareparts({ data: formattedData })
                        // Send the formatted data to your API

                        //setTimeout(UpdateUplaodmastrList, 1000)


                    } catch (error) {
                        notification.error({ message: 'Error parsing Excel file. Please select a valid file.' });
                    }
                };

                reader.readAsArrayBuffer(file);
            } else {
                notification.warn({ message: 'Please select a valid Excel file (XLS or XLSX) to upload.' });
            }
        } else {
            notification.warn({ message: 'Please select a file to upload.' });
        }
    };

    const handleUploadFile = async () => {
        if (fileList.length > 0) {
            const file = fileList[0];

            if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const reader = new FileReader();

                reader.onload = async (event) => {
                    try {
                        const data = new Uint8Array(event.target.result);
                        const workbook = XLSX.read(data, { type: 'array' });

                        const sheetName = workbook.SheetNames[0];
                        const sheet = workbook.Sheets[sheetName];

                        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                        if (jsonData[1].length === 0) {
                            notification.error({ message: 'Invalid file. Please select a valid file.' });
                            return;
                            //throw new Error('Invalid file. Please select a valid file.');
                        }
                        //console.log(jsonData)
                        //const headers = jsonData[0];

                        const keyMapping = {
                            'ID': 'ID',
                            'Part Number': 'partNumber',
                            'Description': 'description',
                            'Qty/5000 Welds': 'quantity_5000_Welds',
                            'Category': 'category',
                            'Multiply Item': 'multiply_Item',
                            'Cost': 'cost',
                            'Where Used': 'where_Used',
                            'UOM': 'UOM',
                            'Alternate Parts': 'alternate_Parts',

                        };


                        const headers = jsonData[0];

                        const allHeadersAvailable = Object.keys(keyMapping)
                            .filter(key => key !== 'ID') // Exclude 'ID' key
                            .every(header => headers.includes(header));
                        console.log(allHeadersAvailable);

                        if (!allHeadersAvailable) {
                            notification.error({ message: 'Invalid header file. Please select a valid file.' });
                            return;
                            //throw new Error('Invalid file. Please select a valid file.');
                        }
                        const formattedData = jsonData.slice(1).map(row => {
                            const rowData = {};
                            headers.forEach((header, index) => {
                                const mappedKey = keyMapping[header]
                                const value = row[index];
                                if (mappedKey !== undefined) {
                                    rowData[mappedKey] = value !== undefined ? value : ''; // Set value to empty string if undefined
                                }
                            });
                            return rowData;
                        });

                        setConfirmLoading(true)
                        await onUploaMasterSpareparts({ data: formattedData })
                        // Send the formatted data to your API

                        //setTimeout(UpdateUplaodmastrList, 1000)


                    } catch (error) {
                        notification.error({ message: 'Error parsing Excel file. Please select a valid file.' });
                    }
                };

                reader.readAsArrayBuffer(file);
            } else {
                notification.warn({ message: 'Please select a valid Excel file (XLS or XLSX) to upload.' });
            }
        } else {
            notification.warn({ message: 'Please select a file to upload.' });
        }
    };
    const showBulkUploadModal = () => {

        setBulkUplaodModal(true);
    }
    const [searchCriteria, setSearchCriteria] = useState({

        'partNumber': '',
        'description': '',
        'quantity_5000_Welds': '',
        'category': '',
        'multiply_Item': '',
        'where_Used': '',
        'cost': '',
        'UOM': '',
        'alternate_Parts': '',
    });
    function hasNonEmptyTag(jsonObj) {
        for (const key in jsonObj) {
            if (jsonObj.hasOwnProperty(key)) {
                const value = jsonObj[key];
                //console.log(value)

                if (value.trim() !== '') {
                    return true;

                }
            }
        }
        return false;
    }
    
    useEffect(() => {

        const isvalid = hasNonEmptyTag(searchCriteria)
        let obj = {
            pageSize: isvalid ? 10 : currentPagesize,
            pageNumber: isvalid ? 1 : currentPageNumber,
            searchKey: searchCriteria

        };
        onFetchSparePartsMasterData(obj)

    }, [searchCriteria]);
    const handleSearchInputChange = (fieldName, value) => {
        setSearchCriteria(prevSearchCriteria => ({
            ...prevSearchCriteria,
            [fieldName]: value
        }));
    };

    const updatedColumns = columns.map(column => {
        if (column.dataIndex === 'creationDate' || column.dataIndex === 'updatedDate') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', width: '100%' }}>
                        {column.title}
                        {/* <DatePicker
                            allowClear
                            style={{ height: '28px', border: 'line', width: '100%' }}
                            format="DD-MM-YYYY"
                            placeholder='Search'
                            onChange={date => handleSearchDateSelect(column.dataIndex, date)}
                            bordered
                        /> */}
                    </Space>
                ),
            };
        } else if (column.dataIndex !== 'action') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', width: '100%' }}>
                        {column.title}
                        <div className='searchEquip'>
                            <Input
                                placeholder='Search'
                                value={searchCriteria[column.dataIndex]}
                                onChange={e => handleSearchInputChange(column.dataIndex, e.target.value)}
                                allowClear
                            />
                        </div>
                    </Space>
                ),
            };
        } else {
            return column;
        }
    });

    return (

        <div className="d-flex flex-column h-100">

        <div className="flex-auto">
            <div className="d-flex mb-3 align-items-center">
                <div className='back_equip' >
                    <Tooltip title={'Back'} >
                        <ArrowLeftOutlined onClick={() => {
                            setDatabaseList([])
                            setShowMasterModel(false)
                            history.push('/SparePartsGenerator');
                        }} />
                    </Tooltip>
                </div>

                <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                    Master Spare Parts List
                </Title>

                <div className="new-calibration">

                    <Button shape="round" key="apply" type="primary" onClick={handleDownloadTemplate}>
                        Download Master List
                    </Button>

                    <Text onClick={() => setShowAddNewModal(true)} className="ps-3 add-new-calibration" >
                        <span className="icon-22" style={{ scale: '20px' }}>
                            <svg>
                                <use xlinkHref="#add" />
                            </svg>
                        </span>
                        Add New
                    </Text>


                    <Text onClick={showBulkUploadModal} className="ps-3 add-new-calibration" >
                        <span className="icon-22" style={{ scale: '20px' }}>
                            <svg>
                                <use xlinkHref="#add" />
                            </svg>
                        </span>
                        Bulk Upload
                    </Text>
                </div>

            </div>
            <div className="custom-line" />
            <div className='addTeamDetails' >
                <Table
                className="h-100 pipeline-table equip_table equipPaginationTable"
                    scroll={{ y: 'calc(100vh - 210px)' }}
                    pagination={
                        sparePartsMasterListCount > 10 ? {
                            position: ['bottomRight'],
                            className: 'fixed-pagination',
                            showSizeChanger: true,
                            total: sparePartsMasterListCount,
                            defaultPageSize: 10,
                            defaultCurrent: 1,

                            onChange: async (pageNumber, pageSize) => {
                                // console.log(licenseTotalCount, pageNumber, pageSize)
                                setCurrentPageNumber(pageNumber);
                                setCurrentPagesize(pageSize);
                                var obj = {
                                    pageSize: pageSize,
                                    pageNumber: pageNumber,
                                    searchKey: searchCriteria
                                }
                                await onFetchSparePartsMasterData(obj)

                            }
                        }
                            : false
                    }


                    dataSource={[...sparePartsMasterData]}
                    loading={isSpartPartsMasterLoading}
                    columns={updatedColumns}

                >

                </Table>
            </div>
            <Modal
                className="pipeline-modal"
                title='Upload Master Spare Parts'
                centered
                visible={bulkUplaodModal}
                footer={[
                    <Button key="cancel" onClick={handleCancelUploadFile}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleUploadFile} loading={isDatabaseMasterLoading}>
                        {'Upload'}
                    </Button>
                ]}
            >
                <div className='uploadFile_admin'>
                    <Upload {...fileProps} maxCount={1} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
                        <Button disabled={disableFileUpload} icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                    </Upload>
                </div>
            </Modal>
            {
                showAddNewModal &&
                <MasterAddnew
                    showAddNewModal={showAddNewModal}
                    setShowAddNewModal={setShowAddNewModal}
                    addNewForm={addNewForm}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    editRecord={editRecord}
                    seteditRecord={seteditRecord}
                >

                </MasterAddnew>
            }


            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Master Spare part Details"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeleteLoading}>
                        {isDeleteLoading ? 'Deleting' : 'Delete'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        <p>Deleting Master Spare Parts Details will delete all the corresponding  related information.</p>
                        Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedData && selectedData.partNumber}</b> part number?</Text>
                </div>
            </Modal>
            </div>
        </div>
    );
};
const mapStateToProps = ({ sparepartsreducer }) => {
    const databaseMasterList = get(sparepartsreducer, 'DatabaseMasterList', []);
    const isDatabaseMasterLoading = get(sparepartsreducer, 'isUploadMasterSparts', false);
    const databaseMasterListCount = get(sparepartsreducer, 'DatabaseMasterListCount', null);

    const sparePartsMasterData = get(sparepartsreducer, 'SparePartsMasterListPage', []);
    const isSpartPartsMasterLoading = get(sparepartsreducer, 'isMasterListPageLoading', false);
    const sparePartsMasterListCount = get(sparepartsreducer, 'SparePartsMasterListPageCount', null);

    const isDeleteLoading = get(sparepartsreducer, 'isDeleteMasterLoading', false);
    return {
        databaseMasterList,
        isDatabaseMasterLoading,
        databaseMasterListCount,


        sparePartsMasterData,
        isSpartPartsMasterLoading,
        // isUploadLicsense,
        sparePartsMasterListCount,
        isDeleteLoading
    }
}

const mapDispatchToProps = {
    onFetchDatabaseList: fetchSparePartsMasterList,
    onUploaMasterSpareparts: uploadMsterSparePartsAction,
    onFetchSparePartsMasterData: fetchSparePartsMasterListPage,
    OnDeleteMasterList: deleteMasterList

}


export default connect(mapStateToProps, mapDispatchToProps)(MasterUploadForm);

