import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { Button, Select, Pagination, Spin } from 'antd';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import * as XLSX from 'xlsx';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { fetchRawProjectSummeryList } from '../../../redux/projectMargin/action';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
//ag grid enterprise
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { cellFormatter, cellFormatterYear, processPivotResultColGroupDef, processPivotResultColGroupDefPeroid, restoreGridLayout, saveGridLayout } from '../Uitiles';
// import axiosConfig from './axiosConfig';
ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule,
    StatusBarModule,
    RowGroupingModule
]);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE)

const { Option } = Select;

const BlankWbs = (props) => {
    const { onFetchRawProjectSummeryList, rawProjectSummeryList, isRawProjectSummeryListLoading, selectedYear, 
        setSelectedYear, year, setYear,layoutSave, layoutLoad,setLayoutSave,setLayoutLoad } = props;

    const [theme, setTheme] = useState('ag-theme-material-dark'); // Default theme
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [pageSize, setPageSize] = useState(60000);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectModel, setSelectModel] = useState([]);
    const [loadingRows, setLoadingRows] = useState(false);

   

    const [columnDefs, setColumnDefs] = useState([
        // { field: 'ID', headerName: 'ID', editable: false, filter: 'agMultiColumnFilter', rowDrag: true, enablePivot: false },
        // column grouping
        { field: 'year', headerName: 'Year', editable: true, filter: 'agMultiColumnFilter', pivot: true, pivotComparator: (a, b) => b.localeCompare(a) },
        { field: 'Year_Period', headerName: 'Year-Period', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'period', headerName: 'Period', editable: true, filter: 'agMultiColumnFilter', pivot: true, pivotComparator: (a, b) => { return processPivotResultColGroupDefPeroid(a, b) } },
        { field: 'GL_Category2', headerName: 'GL Category2', editable: true, filter: 'agMultiColumnFilter', pivot: true ,  pivotComparator: (a, b) => b.localeCompare(a)},
        //
        { field: 'docDate', headerName: 'Doc Date', editable: true, filter: 'agDateColumnFilter', minWidth: 190 },
        { field: 'profitCtr', headerName: 'Profit Ctr', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'costCtr', headerName: 'Cost Ctr', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'trPrt', headerName: 'Tr.Prt', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'account', headerName: 'Account', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'gLAcctLongText', headerName: 'G/L Acct Long Text', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'salesOrd', headerName: 'Sales ord.', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'order', headerName: 'Order', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'material', headerName: 'Material', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'purDoc', headerName: 'Pur. Doc.', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'vendCustNo', headerName: 'Vend/Cust No', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'wbsElement', headerName: 'WBS element', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'userName', headerName: 'User name', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'amountInDocCurr', headerName: 'Amount in doc. curr.', editable: true, filter: 'agMultiColumnFilter', enablePivot: false ,cellStyle: { textAlign: 'right' }, valueFormatter: cellFormatter },
        { field: 'curr', headerName: 'TC', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'amountInLocalCurr', headerName: 'Amount in local cur.', editable: true, filter: 'agMultiColumnFilter', enablePivot: false , cellStyle: { textAlign: 'right' },valueFormatter: cellFormatter },
        { field: 'lCurr', headerName: 'LC', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'reference', headerName: 'Reference', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'documentHeaderText', headerName: 'Document Header Text', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'Proj_Master', headerName: 'Proj Master', editable: true, filter: 'agMultiColumnFilter' },
        // { field: 'FXReference', headerName: 'FX Reference', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'AmountUSD', headerName: 'Amount USD', editable: true, filter: 'agMultiColumnFilter', aggFunc: "sum", enablePivot: false,cellStyle: { textAlign: 'right' }, valueFormatter: cellFormatter },
      
        { field: 'Cust_Name', headerName: 'Cust Name', editable: true, filter: 'agMultiColumnFilter' },
        //row grouping
        { field: 'coCode', headerName: 'CoCode', editable: true, filter: 'agMultiColumnFilter', rowGroup: true, enableRowGroup: true },
        // { field: 'documentNo', headerName: 'Document No', editable: true, filter: 'agMultiColumnFilter', rowGroup: true, enableRowGroup: true, enablePivot: false },
        { field: 'Proj_Description', headerName: 'Proj Description', editable: true, filter: 'agMultiColumnFilter', rowGroup: true, enableRowGroup: true },
        { field: 'WBS_Name', headerName: 'WBS Name', editable: true, filter: 'agMultiColumnFilter', rowGroup: true, enableRowGroup: true },
        { field: 'WBS_Element2', headerName: 'WBS Element 2', editable: true, filter: 'agMultiColumnFilter', rowGroup: true, enableRowGroup: true },
        { field: 'wbsElement', headerName: 'WBS element', editable: true, filter: 'agMultiColumnFilter', rowGroup: true, enableRowGroup: true },
        { field: 'assignment', headerName: 'Assignment', editable: true, filter: 'agMultiColumnFilter', rowGroup: true, enableRowGroup: true },
        { field: 'text', headerName: 'Text', editable: true, filter: 'agMultiColumnFilter', rowGroup: true, enableRowGroup: true },
        //
        { field: 'GL_Category3', headerName: 'GL Category3', editable: true, filter: 'agMultiColumnFilter'},
        { field: 'Channel', headerName: 'Channel', editable: true, filter: 'agMultiColumnFilter'},
        { field: 'Channel2', headerName: 'Channel2', editable: true, filter: 'agMultiColumnFilter'},
        { field: 'WBS_Status', headerName: 'WBS Status', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PROJECT_NAMES', headerName: 'PROJECT NAMES', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'WBS_Level', headerName: 'WBS Level', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'WBS_Element_Name', headerName: 'WBS Element Name', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PC_Name', headerName: 'PC Name', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'GL_Grouping_L1', headerName: 'GL Grouping L1', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'GL_Description', headerName: 'GL Description', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'Product_Line', headerName: 'Product Line', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Channel1', headerName: 'PMP Channel1', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Channel2', headerName: 'PMP Channel2', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Project_Number', headerName: 'PMP Project Number', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Project_Name', headerName: 'PMP Project Name', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_CoCode', headerName: 'PMP CoCode', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Country_Of_Work', headerName: 'PMP Country Of Work', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Location', headerName: 'PMP Location', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Market', headerName: 'PMP Market', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Customer', headerName: 'PMP Customer', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Framework_Agreement', headerName: 'PMP Framework Agreement', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMW_WBS_Element', headerName: 'PMW WBS Element', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMW_WBS_Element_Name', headerName: 'PMW WBS Element Name', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMW_Revenue_Type', headerName: 'PMW Revenue Type', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Risk_Profile', headerName: 'PMP Risk Profile', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'PMP_Service', headerName: 'PMP Service', editable: true, filter: 'agMultiColumnFilter' },
        { field: 'Assignment_Proj_Tag', headerName: 'Assignment Proj Tag', editable: true, filter: 'agMultiColumnFilter' },
    ]);

    useEffect(() => {
        if (rawProjectSummeryList.length==0 && !isRawProjectSummeryListLoading) {
            onFetchRawProjectSummeryList({ tableName: 'Project_Summary_Consol', pageNumber: pageNumber, pageSize: pageSize });
        }
    }, [])

    // Check for changes in rawProjectSummeryList and fetch more data if needed
    useEffect(() => {
        if (rawProjectSummeryList.length > 0) {
            // const year = [...new Set(rawProjectSummeryList.map(item => item.year))];
            // const filteredRow= rawProjectSummeryList.filter(item => item.year==year[0])
            // setSelectedYear(year[0])
            setRowData(rawProjectSummeryList);
            // setYear(year);
        }
    }, [rawProjectSummeryList]);

    // Check for row data on the basic of year
    // useEffect(() => {
    //     if (rawProjectSummeryList.length > 0) {
    //         const filteredRow= rawProjectSummeryList.filter(item => item.year==selectedYear)
    //         setRowData(filteredRow);
    //     }
    // }, [selectedYear]);


    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const binaryStr = event.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

            const columns = sheet[0].map((header, index) => ({
                field: header,
                editable: true,
                filter: true,
                enableValue: header !== 'name' && header !== 'id',
                rowDrag: index === 0 ? true : false
            }));

            const rows = sheet.slice(1).map((row) =>
                row.reduce((acc, value, index) => {
                    acc[sheet[0][index]] = value;
                    return acc;
                }, {})
            );

            setColumnDefs(columns);
            // setRowData(rows);
        };
        reader.readAsBinaryString(file);
    };

    const onRowDragEnd = (event) => {
        const { node, overNode } = event;
        if (!overNode) return;

        const draggedRow = node.data;
        const fromIndex = node.rowIndex;
        const toIndex = overNode.rowIndex;

        if (fromIndex === toIndex) return;

        const newRowData = [...rowData];
        newRowData.splice(fromIndex, 1);
        newRowData.splice(toIndex, 0, draggedRow);

        setRowData(newRowData);
    };

      useEffect(() => {
        if (layoutSave && gridRef) {
            (async () => {
                let layouts = {}
                await saveGridLayout(gridRef,currentTab,layouts,setLayoutSave);
            })();
           
        }
      

    }, [layoutSave])

    useEffect(() => {
        if (layoutLoad && gridRef)
        {
            (async () => {
                await restoreGridLayout(gridRef, currentTab,);
                setLayoutLoad(false)
            })();
           
            
        }
    }, [layoutLoad])

   

    const gridStyle = useMemo(() => ({ height: "99%", width: "100%" }), []);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 200,
            // floatingFilter: true,
            resizable: true,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
            filter: true,
            filterParams: {
            comparator: (a, b) => a.localeCompare(b)
            },
            cellClassRules: {
                "pivot-total-value": (params) => params.node.footer, // Apply class only to footer rows
            },
        };
    }, []);

    const currentTab = 'Blank WBS';
    const defaultExcelExportParams = useMemo(() => {
        return {
            fileName: `${currentTab}.xlsx`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
            sheetName: "Sheet1", // Default sheet name
        };
    }, [currentTab]);
    const defaultCsvExportParams = useMemo(() => {
        return {
            fileName: `${currentTab}.csv`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
        };
    }, [currentTab]); 

    const rowSelection = useMemo(() => {
        return { mode: "multiRow" };
    }, []);

    const onGridReady = useCallback((params) => {
        if (isRawProjectSummeryListLoading) {
            params.api.showLoadingOverlay();
        } else {
            params.api.hideOverlay();
        }
    }, [isRawProjectSummeryListLoading]);

    // Update loading state
    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            if (isRawProjectSummeryListLoading) {
                gridRef.current.api.showLoadingOverlay();
            } else {
                gridRef.current.api.hideOverlay();
            }
        }
    }, [isRawProjectSummeryListLoading]);

    // Update loading state
    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            if (loadingRows) {
                gridRef.current.api.showLoadingOverlay();
            } else {
                gridRef.current.api.hideOverlay();
            }
        }
    }, [loadingRows]);

    const [statusBar] = useState({
        statusPanels: [
            {
                statusPanel: 'agTotalRowCountComponent',
                align: 'left'
            },
            // {
            //     statusPanel: 'agSelectedRowCountComponent',
            //     align: 'left'
            // },
            // {
            //     statusPanel: 'agFilteredRowCountComponent',
            //     align: 'left'
            // }
        ]
    });

    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 100,
            pinned: "left",
            cellRendererParams: {
                suppressCount: true,
            }
        };
    }, []);

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        console.log('Selected Row Details:', selectedRows);
        setSelectModel(selectedRows)
        // You can do further processing with selectedRows here, e.g., updating state
    };

    //
    const sideBar = useMemo(() => { 
        return {
            toolPanels: ['columns', 'filters']
        };
    }, []);

    return (
        <div style={{ height: 'calc(100vh - 120px)', overflow: 'auto', position: 'relative' }}>
            <div className={`ag-grid ${theme} custom-grid-theme`} style={gridStyle}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    rowDragManaged={true}
                    onRowDragEnd={onRowDragEnd}
                    defaultColDef={defaultColDef}
                    // rowSelection={rowSelection}
                    onGridReady={onGridReady}
                    sideBar={sideBar}
                    pivotMode={true}
                    //
                    pivotPanelShow={"always"}
                    autoGroupColumnDef={autoGroupColumnDef}
                    animateRows={true}
                    onSelectionChanged={onSelectionChanged}
                    enableFilter={true}
                    statusBar={statusBar}
                    groupDefaultExpanded={8}
                    pivotDefaultExpanded={0}
                    // pivotRowTotals={"after"}
                    // pivotColumnTotals={true}
                    // groupTotalRow={"bottom"}
                    grandTotalRow={"bottom"}
                    groupDisplayType={"multipleColumns"}
                    showOpenedGroup={true}
                    suppressGroupRowsSticky={true}
                    rowHeight={40}
                    headerHeight={40}
                    enableStrictPivotColumnOrder = {true}
                    removePivotHeaderRowWhenSingleValueColumn ={true}
                    defaultExcelExportParams={defaultExcelExportParams}
                    defaultCsvExportParams={defaultCsvExportParams}
                    processPivotResultColGroupDef={processPivotResultColGroupDef}
                />
            </div>
        </div>
    );
};

const mapStateToProps = ({ projectMarginReducer }) => {
    const isRawProjectSummeryListLoading = get(projectMarginReducer, 'isRawProjectSummeryListLoading', false);
    const rawProjectSummeryList = get(projectMarginReducer, 'rawProjectSummeryList', []);

    return {
        isRawProjectSummeryListLoading,
        rawProjectSummeryList,
    }
}

const mapDispatchToProps = {
    onFetchRawProjectSummeryList: fetchRawProjectSummeryList,
}

export default connect(mapStateToProps, mapDispatchToProps)(BlankWbs);
