/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
    Button, Form,
    Input,
    Modal,
    Space,
    Table,
    Tabs,
    Tooltip,
    Typography,
    Upload, notification, Row, Col,
    InputNumber,
    DatePicker
} from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';
import { get, head } from 'lodash';
import moment from 'moment';
import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import history from '../../services/history.js';
import { AddOrUpdateConfigData, DeleteMasterDetails, getListFromDatabase, GetRecordListExcel } from '../../redux/FinanceMapping/saga.js';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';
//ag grid enterprise
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule,
    StatusBarModule,
    RowGroupingModule
]);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE)



const { TabPane } = Tabs;
const { Title, Text } = Typography;
//const { TabPane } = Tabs;
//const { Column } = Table;
//const { Search } = Input;

//const { Option } = Select;
const MasteForm_Mappings = (props) => {

    const {
        setIsMasterModalVisible,
        isMasterModalVisible,

        userGroup
    } = props;


    const [UpdateModalForm] = Form.useForm()

    const [searchText, setSearchText] = useState({});
    const [searchedColumn, setSearchedColumn] = useState('');

    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [currentPagesize, setCurrentPagesize] = useState(10);

    const [masterUploadForm] = Form.useForm()
    const [addNewForm] = Form.useForm()
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [bulkUplaodModal, setBulkUplaodModal] = useState(false);
    const [databaseList, setDatabaseList] = useState([]);
    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editRecord, seteditRecord] = useState('');

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [matrialClassTypes, setMatrialClassTypes] = useState([]);
    const [selectedMatrialClass, setSelectedMatrialClass] = useState('');

    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [activeKey, setActiveKey] = useState('1')
    const [activeKeyLine, setActiveKeyLine] = useState('1')
    const [searchCriteria, setSearchCriteria] = useState({});
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);

    const [addLoding, setAddLaoding] = useState(false)
    const [loading, setloading] = useState(false);
    const [edittModalVisible, setEdittModalVisible] = useState(false)
    const [isUpdateLoading, setUpdateLoading] = useState(false)
    const [addItem, setAddItem] = useState(11.2);
    const [TableDisplayList, setTableDisplayList] = useState([])
    const [TableIndexList, setTableIndexList] = useState([])
    const [AddUpdateList, setAddUpdateList] = useState({})
    const [data_List, setData_List] = useState([]);
    const [rentalPrice, setRentalPrice] = useState(11.2);
    const [CommonInputUpdating, setCommonInputUpdating] = useState(false)


    const [exportLoading, setExportLoading] = useState(false);
    const [TotalNumber, setTotalNumber] = useState(0);


    const [theme, setTheme] = useState('ag-theme-material-dark'); // Default theme
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);




    const tableNames = {
        Accounting_Periods: 'Accounting Periods',
        Asset_Class: 'Asset Class',
        Chart_Accounts: "Chart Accounts",
        Company_Codes: 'Company Codes',
        Cost_Center: 'Cost Center',
        Document_Types: 'Document Types',
        Exchange_Rates: 'Exchange Rates',
        Functional_Area: 'Functional Area',
        Plants: 'Plants',
        Profit_Center: 'Profit Center',
        Project_Mappings: 'Project Mappings',
        Project_Mappings_WBS_Elements: 'Project Mappings WBS Elements',
        Trading_Partners: 'Trading Partners',
        TP_Matrix_Match: 'TP Matrix Match',
    };

    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setDisableFileUpload(false);
        },
        beforeUpload: (file) => {

            setFileList([file]);
            setDisableFileUpload(true);
            return false;
        },
        fileList,
    };
    const showBulkUploadModal = () => {
        setBulkUplaodModal(true);
    }
    const handleCancelUploadFile = () => {
        setBulkUplaodModal(false);
    }
// CSS for centering headers
const gridStyle1 = `
.text-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
`;
    const generateColumns = (titles, dataIndices, onUpdateClick, showDeleteModal) => {
        // Create an array of columns based on titles and dataIndices
        const columnDefs = titles.map((title, index) => ({
            headerName: title,
            field: dataIndices[index],
            key: dataIndices[index],
            cellRenderer: (params) => {
                const colValue =
                    title === 'Start Date' ||
                    title === 'End Date' ||
                    title === 'Date' ||
                    title === 'Valid To' ||
                    title === 'Valid From' ||
                    title === 'Created on';
                return colValue ? moment(params.value).format('DD-MMM-YYYY') : params.value;
            },
            cellStyle: { textAlign: 'center' },
            headerClass: {
                display: 'flex',
                justifycontent: 'center',
                alignitems: 'center',
                textalign: 'center'
            }
        }));

        // Add the actions column at the end
        columnDefs.push({
            headerName: 'Actions',
            field: 'action',
            cellRenderer: (params) => {
                const record = params.data;
                return (
                    <div>
                       
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px',  color:'#ef314c' }}
                                    onClick={() => onUpdateClick(record)}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px', borderColor:'red' }}>
                                <DeleteOutlined style={{ fontSize: '18px', color:'#ef314c' }}
                                    onClick={() => showDeleteModal(record)}
                                />
                            </span>
                        </Tooltip>
                       
                    </div>
                );
            },
            cellStyle: { textAlign: 'center' },
            filter: false, // Disable filtering for the Actions column
        });

        return columnDefs;
    };

    const ExcelDateToJSDate = (date) => {
        const convertedDate = new Date(Math.round((date - 25569) * 864e5));
        return String(convertedDate).slice(4, 15);
    };

    const handleUploadFile3 = async () => {
        if (fileList.length > 0) {
            const file = fileList[0];
            if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const reader = new FileReader();
                reader.onload = async (event) => {
                    try {
                        const data = new Uint8Array(event.target.result);
                        const workbook = XLSX.read(data, { type: 'array' });

                        const headersSet = new Set();
                        //   for (let index = 0; index < workbook.SheetNames.length; index++)
                        {
                            const sheetName = workbook.SheetNames[0];
                            const sheet = workbook.Sheets[sheetName];
                            // console.log('SheetName', sheetName)
                            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                            if (jsonData.length === 0) {
                                notification.error({ message: `Sheet '${sheetName}' is empty or invalid.` });
                                return
                            }
                            const headers = jsonData[0];
                            // headers.forEach(header => headersSet.add(header.toLowerCase().replace(/ /g, '_')));
                            // Create a mapping of headers to keys with spaces and slashes replaced by underscores
                            const keyMapping = headers.reduce((acc, header) => {
                                let key = header
                                    .replace(/ /g, '_') // Replace spaces with underscores
                                // Remove leading underscore if added due to space at the start
                                if (key.startsWith('_')) {
                                    key = key.substring(1);
                                }
                                acc[header] = key;
                                headersSet.add(key);
                                return acc;
                            }, {});
                            //  console.log('headers', headers)



                            // Filter keys excluding unwanted fields
                            const keys = Object.keys(data_List[0]).filter(key =>
                                key !== 'Created_date' && key !== 'created_date' && key !== 'Updated_date' && key !== 'ID' && key != 'totalCount' && key != 'totalListCount'
                            );


                            const transformedKeys = keys.map(key => key.replace(/_/g, ' '));
                            // console.log('headers', transformedKeys)
                            // Check if all required transformedKeys are available in the headers from the Excel file
                            const allHeadersAvailable = transformedKeys
                                .filter(key => key !== 'ID') // Exclude 'ID' key
                                .every(header => headers.includes(header));

                            //  console.log('All headers available:', allHeadersAvailable);

                            if (!allHeadersAvailable) {
                                notification.error({ message: 'Invalid header file. Please select a valid file.' });
                                return;
                            }
                            setUploadLoading(true)
                            const formattedData = jsonData.slice(1).map((row, index) => {
                                const rowData = {};
                                // rowData["ID"] = index + 1;
                                headers.forEach((header, index) => {
                                    const mappedKey = keyMapping[header];
                                    const value = row[index];
                                    if (header === 'End Date' || header === 'Start Date' || header === 'Created on' || header === 'Created On' || header == 'Valid From' || header == 'Valid To' || header == 'Date') {
                                        const formattedDate = moment(ExcelDateToJSDate(value), "DD MM YYYY").format("YYYY-MM-DD");
                                        if (!formattedDate.includes('Invalid date'))
                                            rowData[mappedKey] = formattedDate
                                        else {
                                            const formattedDate = moment(value, "M-D-YY").format("YYYY-MM-DD");
                                            if (!formattedDate.includes('Invalid date'))
                                                rowData[mappedKey] = formattedDate
                                            else {
                                                const formattedDate = moment(value, "DD-MM-YY").format("YYYY-MM-DD");
                                                if (!formattedDate.includes('Invalid date'))
                                                    rowData[mappedKey] = formattedDate
                                                else {
                                                    const formattedDate = moment(ExcelDateToJSDate(value), "MMM DD YYYY").format("YYYY-MM-DD");
                                                    rowData[mappedKey] = formattedDate
                                                }
                                            }
                                        }
                                    }
                                    else if (mappedKey !== undefined) {
                                        rowData[mappedKey] = value !== undefined ? value.toString() : ''; // Set value to empty string if undefined
                                    }
                                });
                                return rowData;
                            });
                            // formattedData.reverse();

                            //  console.log('tabelName', activeKeyLine)
                            //console.log('formattedData', formattedData)
                            const tableData = {
                                data: formattedData,
                                tableName: activeKeyLine
                            }
                            await AddOrUpdateConfigData(tableData)
                            setUploadLoading(false)
                            setBulkUplaodModal(false);
                        }

                    } catch (error) {
                        notification.error({ message: 'Error parsing Excel file. Please select a valid file.' });
                    }
                };
                reader.readAsArrayBuffer(file);
            } else {
                notification.warn({ message: 'Please select a valid Excel file (XLS or XLSX) to upload.' });
            }
        } else {
            notification.warn({ message: 'Please select a file to upload.' });
        }
    };




    useEffect(() => {
        (async () => {
            if (data_List.length == 0) {

                const initialClass = Object.keys(tableNames)[0];
                handleTabChange(initialClass)


            }
            // setloading(false)
        })();
    }, []);






    const handleTabChange = async (key) => {

        // console.log(key)
        setloading(true)

        const response = await getListFromDatabase(key)
        if (response) {

            console.log('Record Item', response);
            if (response.length > 0) {
                //  const keys = Object.keys(response[0]).map(key => key);
                const keys = Object.keys(response[0]).filter(key =>
                    key !== 'Created_date' && key !== 'created_date' && key !== 'Updated_date' && key !== 'ID' && key != 'totalCount' && key != 'totalListCount'

                );
                setTableIndexList(keys)

                const filteredObject = Object.fromEntries(
                    Object.entries(response[0]).filter(
                        ([key]) => key !== 'Created_date' && key !== 'Updated_date' && key !== 'ID' && key != 'totalCount' && key != 'totalListCount'

                    )
                );
                setAddUpdateList(filteredObject)

                const transformedKeys = keys.map(key => key.replace(/_/g, ' '));
                setTableDisplayList(transformedKeys)

                setActiveKeyLine(key);
                setSelectedMatrialClass(key);
                setData_List(response);
            }

        };
        setloading(false)
    }

    const FetchDatalist = async () => {
        setloading(true)


        const response = await getListFromDatabase(activeKeyLine)
        if (response) {
            setData_List(response);
        }

        setloading(false)
    }



    const onUpdateClick = (record) => {
        // console.log(record)
        //'Start Date' || item==='End Date' || item==='Date' || item==='Valid To' || item==='Valid From' || item==='Created on'
        if (record) {
            record.Start_Date = moment(record.Start_Date, 'YYYY-MM-DD')
            record.End_Date = moment(record.End_Date, 'YYYY-MM-DD')
            record.Created_on = moment(record.Created_on, 'YYYY-MM-DD')
            record.Valid_From = moment(record.Valid_From, 'YYYY-MM-DD')
            record.Valid_To = moment(record.Valid_To, 'YYYY-MM-DD')
            record.Date = moment(record.Date, 'YYYY-MM-DD')
            addNewForm.setFieldsValue(record)
            seteditRecord(record)
            setAddUpdateList(record)
            setIsEdit(true)
            setShowAddNewModal(true)
        }
    }

    const handleCancel = async () => {

        setRentalPrice('')
        setEdittModalVisible(false)
        UpdateModalForm.resetFields()
    };

    const handleAddCancel = async () => {

        setShowAddNewModal(false)
        // UpdateModalForm.resetFields()
    };
    const onRowDragEnd = (event) => {
        const { node, overNode } = event;
        if (!overNode) return;

        const draggedRow = node.data;
        const fromIndex = node.rowIndex;
        const toIndex = overNode.rowIndex;

        if (fromIndex === toIndex) return;

        const newRowData = [...rowData];
        newRowData.splice(fromIndex, 1);
        newRowData.splice(toIndex, 0, draggedRow);

        setRowData(newRowData);
    };


    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 200,
            floatingFilter: true,
            resizable: true,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
            filter: true,
            
        };
    }, []);

    const rowSelection = useMemo(() => {
        return { mode: "multiRow" };
    }, []);

    const onGridReady = useCallback((params) => {
        if (loading) {
            params.api.showLoadingOverlay();
        } else {
            params.api.hideOverlay();
        }
    }, [loading]);

    // Update loading state
    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            if (loading) {
                gridRef.current.api.showLoadingOverlay();
            } else {
                gridRef.current.api.hideOverlay();
            }
        }
    }, [loading]);

    // Update loading state
    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            if (loading) {
                gridRef.current.api.showLoadingOverlay();
            } else {
                gridRef.current.api.hideOverlay();
            }
        }
    }, [loading]);

    const [statusBar] = useState({
        statusPanels: [
            {
                statusPanel: 'agTotalRowCountComponent',
                align: 'left'
            },
            {
                statusPanel: 'agSelectedRowCountComponent',
                align: 'left'
            },
            {
                statusPanel: 'agFilteredRowCountComponent',
                align: 'left'
            }
        ]
    });

    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 200,
            pinned: "left",
        };
    }, []);

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        console.log('Selected Row Details:', selectedRows);
        seteditRecord(selectedRows)
        // You can do further processing with selectedRows here, e.g., updating state
    };


    const getPipelineTabpanes = () => {
      

        return (
            <div className="custom-tab h-100" style={{ paddingTop: '5px' }}>
                <Tabs activeKey={selectedMatrialClass} onChange={handleTabChange} defaultActiveKey={Object.keys(tableNames)[0]}>
                    {Object.keys(tableNames).map((row) => (
                        <TabPane tab={tableNames[row]} key={row}>
                            {
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <div style={{ height: 'calc(105vh - 200px)', overflow: 'auto', position: 'relative' }}>
                                            <div className={`ag-grid ${theme} custom-grid-theme`} style={gridStyle}>
                                                <AgGridReact
                                                    ref={gridRef}
                                                    rowData={data_List || []}
                                                    columnDefs={generateColumns(TableDisplayList, TableIndexList, onUpdateClick, showDeleteModal)}
                                                    rowDragManaged={true}
                                                    onRowDragEnd={onRowDragEnd}
                                                    defaultColDef={defaultColDef}
                                                    rowSelection={rowSelection}
                                                    onGridReady={onGridReady}
                                                    sideBar={true}
                                                    //
                                                    pivotPanelShow={"always"}
                                                    autoGroupColumnDef={autoGroupColumnDef}
                                                    animateRows={true}
                                                    onSelectionChanged={onSelectionChanged}
                                                    // enableFilter={true}
                                                    statusBar={statusBar}
                                                    rowHeight={40}
                                                    headerHeight={50}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </TabPane>
                    ))}
                </Tabs>
            </div>
        );
    };
  

    /** Display Confirmation while deleting */
    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedData(record)
    };
    /** Deleting Spare parts data on confirm */
    const handleDeleteOk = async () => {
        setIsDeleteLoading(true)
        const obj = {
            columnName: activeKeyLine,
            tableName: activeKeyLine,
            data: selectedData
        };
        //  console.log(obj);
        await DeleteMasterDetails(obj)
        setIsDeleteLoading(false)
        setIsDeleteModalVisible(false);

        setSelectedData('');
        setloading(true)
        await FetchDatalist();
        setloading(false)

    }


    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
    }

    //add form data to database
    const handleCreate = async () => {
        addNewForm.validateFields()
            .then(async (values) => {
                let item = { ...values };
                // console.log('Added Item:', item, activeKeyLine);




                if (item.Start_Date) {
                    item.Start_Date = moment(item.Start_Date).format('YYYY-MM-DD');
                }

                if (item.End_Date) {
                    item.End_Date = moment(item.End_Date).format('YYYY-MM-DD');
                }

                if (item.Created_on) {
                    item.Created_on = moment(item.Created_on).format('YYYY-MM-DD');
                }

                if (item.Valid_From) {
                    item.Valid_From = moment(item.Valid_From).format('YYYY-MM-DD');
                }

                if (item.Valid_To) {
                    item.Valid_To = moment(item.Valid_To).format('YYYY-MM-DD');
                }

                if (item.Date) {
                    item.Date = moment(item.Date).format('YYYY-MM-DD');
                }

                // console.log('Added Item:', item);
                setAddLaoding(true)
                if (activeKeyLine) {
                    if(isEdit && editRecord)
                        {
                            item['ID'] = editRecord.ID
                        }
                    const tableData = {
                        data: [item],
                        tableName: activeKeyLine
                    }
                   
    
                   await AddOrUpdateConfigData(tableData)

                    setIsEdit(false);
                    addNewForm.resetFields()
                    setShowAddNewModal(false);
                    await FetchDatalist();
                    setAddLaoding(false)
                }


            })
            .catch(errorInfo => {
                console.error('Validation failed:', errorInfo);
            });
    };


    const handleAddNewModalCancel = () => {
        addNewForm.resetFields();
        setShowAddNewModal(false);
    }
    const createExcelTemplate = async () => {

        // Check if activeKeyLine is available
        if (!activeKeyLine) return;
        setDownloadLoading(true)
        // Fetch data from the database
        const databaseList = await GetRecordListExcel(activeKeyLine);

        // Check if databaseList has data
        if (databaseList && databaseList.length > 0) {
            const sheetName = activeKeyLine.replace(/_/g, ' '); // Format sheet name
            const workbook = new ExcelJS.Workbook();
            const masterstationSheet = workbook.addWorksheet(sheetName);
            const headerRow = masterstationSheet.getRow(1);
            headerRow.height = 20;

            // Filter keys excluding unwanted fields
            const keys = Object.keys(databaseList[0]).filter(key =>
                key !== 'Created_date' && key !== 'created_date' && key !== 'Updated_date'
            );

            // Define the columns for the worksheet
            masterstationSheet.columns = keys.map(key => ({
                header: key.replace(/_/g, ' '), // Replace underscores in header names
                key: key,                       // Use original key for the data
                width: 20                       // Set a default column width
            }));

            console.log(masterstationSheet.columns);

            // Style the header row
            headerRow.eachCell(cell => {
                cell.font = { bold: true };
            });

            // Add data rows to the worksheet
            masterstationSheet.addRows(databaseList);

            // Ensure left alignment for all data rows (optional step, but reinforces alignment)
            masterstationSheet.eachRow((row, rowNumber) => {
                if (rowNumber !== 1) { // Skip the header row
                    row.eachCell(cell => {
                        cell.alignment = { horizontal: 'left' }; // Left-align each cell
                    });
                }
            });

            // Generate and download the Excel file
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${sheetName}.xlsx`;
            link.click();
        }

        setDownloadLoading(false)
    };


    return (

        <div>
            <div className="d-flex mb-3 align-items-center">
                <div className='back_equip' >
                    <Tooltip title={'Back'} >
                        <ArrowLeftOutlined onClick={() => {

                            setIsMasterModalVisible(false)
                        }} />
                    </Tooltip>
                </div>

                {/* <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '25px', paddingTop: '5px' }}>
                    Confguration
                </Title> */}

                <div className="new-calibration" style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <Button shape="round" key="apply" type="primary" onClick={UpdateRentalPrice} loading={downloadLoading}>
                        Update Rental Price
                    </Button> */}


                    <Text onClick={() => setShowAddNewModal(true)} className="ps-3 add-new-calibration" >
                        <span className="icon-22" style={{ marginRight: '5px' }}>
                            <svg style={{ width: 'auto', height: 'auto' }}>
                                <use xlinkHref="#add" />
                            </svg>
                        </span>
                        Add New
                    </Text>
                    <Button shape="round" key="apply" type="primary" onClick={() => createExcelTemplate()} style={{ marginLeft: '20px' }} loading={downloadLoading}>
                        Download As Excel
                    </Button>

                    <Button shape="round" key="apply" type="primary" style={{ marginLeft: '20px' }}
                        onClick={showBulkUploadModal}
                    >
                        <CloudUploadOutlined style={{ fontSize: '20px' }}> </CloudUploadOutlined>
                        Bulk Upload
                    </Button>
                </div>

            </div>
            <div className="custom-line" />
            {getPipelineTabpanes()}

            {/* Add form modal */}
            <Modal
                width="60vw"
                className="pipeline-modal"
                title={isEdit ? 'Update' : 'Add'}
                centered
                visible={showAddNewModal}
                maskClosable={false}
                footer={[
                    <Button key="cancel" onClick={handleAddNewModalCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={() => handleCreate()} loading={addLoding} >
                        {isEdit ? 'Update' : 'Add'}
                    </Button>
                ]}
            >
                <div>
                    <Form
                        form={addNewForm}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Row gutter={24}>
                            {TableDisplayList.map((item, index) => {
                                const colValue = item === 'Start Date' || item === 'End Date' || item === 'Date' || item === 'Valid To' || item === 'Valid From' || item === 'Created on';
                                return (
                                    <Col lg={8}>
                                        <Form.Item
                                            label={item}
                                            name={TableIndexList[index]}
                                            rules={[{ required: true, message: `Please input your ${item.replace(/_/g, ' ')}!` }]}
                                        >
                                            {colValue ?
                                                <DatePicker className='date-picker' format='YYYY-MM-DD' size="large" style={{ width: '100%' }} /> :
                                                <Input size="large" />
                                            }
                                        </Form.Item>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Form>
                </div>
            </Modal>

            <Modal
                className="pipeline-modal"
                title='Upload  Record'
                centered
                visible={bulkUplaodModal}
                footer={[
                    <Button key="cancel" onClick={handleCancelUploadFile}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleUploadFile3} loading={uploadLoading}>
                        {'Upload'}
                    </Button>
                ]}
            >
                <div className='uploadFile_admin'>
                    <Upload {...fileProps} maxCount={1} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
                        <Button disabled={disableFileUpload} icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                    </Upload>
                </div>
            </Modal>


            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Details"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeleteLoading}>
                        {isDeleteLoading ? 'Deleting' : 'Delete'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        <p>Deleting all the corresponding and related information.</p>
                        Are you sure you want to delete  ?</Text>
                </div>
            </Modal>

        </div>
    );
};



export default connect(null, null)(MasteForm_Mappings);

