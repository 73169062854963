/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, RightCircleOutlined } from '@ant-design/icons';
import 'antd/dist/antd.dark.css';
import { Table, Tabs, notification, Tooltip, Typography, Select, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import TieOut from './TieOut';
import PmYoy from './PmYoy';
import PlYoy from './PlYoy';
import ProductLine from './ProductLine';
import ProjectReview from './ProjectReview';
import Commercial from './Commercial';
import { constant } from 'lodash';
import { GetRefreshInfo } from '../../../redux/FinanceMapping/saga';

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { Option } = Select;

const GeneralTabs = () => {
    const history = useHistory();
    const [group, setGroup] = useState(false);
    const [currentSection, setCurrentSection] = useState('Tie Out');
    const [year, setYear] = useState([])
    const [selectedYear, setSelectedYear] = useState(null);
    const [layoutSave, setLayoutSave] = useState(false);
    const [layoutLoad, setLayoutLoad] = useState(false);
    const [refreshInfo, setRefreshInfo] = useState(null);
    const onChange = (key) => {
        setCurrentSection(key);
    };

    useEffect(() => {
        const fetchInfo = async () => {
            try {
                const info = await GetRefreshInfo('Project_Summary_Consol');
                if (info.Date_Time){
                    setRefreshInfo(info.Date_Time)
                }
            } catch (error) {
                console.error("Error fetching info:", error);
            }
        };
    
        fetchInfo();
    }, []);

    return (
        <div className="h-100 d-flex flex-column">
            <div className="d-flex mb-2 tab-content-title" style={{ justifyContent: 'space-between' }}>
                <div className='back_equip' >
                    <Tooltip title={'Back'} >
                        <ArrowLeftOutlined onClick={(e) => {
                            history.push('/project-margin');
                        }} />
                    </Tooltip>
                    <Title className="mb-0  add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                        General
                    </Title>

                </div>
                <div className="new-calibration">

                    <Text className="ps-3 add-new-calibration" style={{ marginTop: '10px', marginRight: '20px' }}>Refresh Date: {refreshInfo}</Text>
                    {
                        // userGroup && userGroup.includes('pricing-admin') &&
                        <Button shape="round" key="apply" type="primary" style={{ marginRight: '20px' }}
                            // onClick={showMasterModal}
                            onClick={() => {
                                history.push('/raw-data');
                            }}
                        >
                            Raw Data
                        </Button>

                    }

                    <Button shape="round" key="apply" type="primary" style={{ marginRight: '20px' }} loading={layoutSave}
                        // onClick={showMasterModal}
                        onClick={() => {
                            setLayoutSave(true)

                        }}
                    >
                        Save Layout
                    </Button>

                    {/* <Button shape="round" key="apply" type="primary" style={{ marginRight: '20px' }} loading= {layoutLoad}
                        // onClick={showMasterModal}
                        onClick={() => {
                            setLayoutLoad(true)
                        }}
                    >
                       Load Layout
                    </Button> */}

                </div>
                <div style={{}}>
                    {/* <Select
                        className="maintenance-select"
                        placeholder="Select Year"
                        onChange={(value) => setSelectedYear(value)}
                        value={selectedYear}
                        size="medium"
                        showSearch
                        allowClear
                        defaultValue={year.length > 0 ? year[0] : undefined}
                        style={{width:'150px', paddingLeft:'5px', paddingRight:'5px' }}
                    >
                        {year.length > 0 &&
                            year.map((item, i) => (
                                <Option key={i} value={item}>
                                    {item}
                                </Option>
                            ))}
                    </Select> */}

                </div>

            </div>

            <div className="flex-fill">
                <Tabs
                    className="custom-tab h-100"
                    onChange={onChange}
                >
                    <TabPane tab="Tie Out" key="Tie Out">
                        {currentSection == 'Tie Out' ?
                            <TieOut selectedYear={selectedYear} setSelectedYear={setSelectedYear} year={year} setYear={setYear} currentTab={currentSection} layoutSave={layoutSave} layoutLoad={layoutLoad}
                                setLayoutSave={setLayoutSave} setLayoutLoad={setLayoutLoad} />
                            : null
                        }
                    </TabPane>
                    <TabPane tab="PM YoY" key="PM YoY">
                        {currentSection == 'PM YoY' ?
                            <PmYoy selectedYear={selectedYear} setSelectedYear={setSelectedYear} year={year} setYear={setYear} currentTab={currentSection} layoutSave={layoutSave} layoutLoad={layoutLoad}
                                setLayoutSave={setLayoutSave} setLayoutLoad={setLayoutLoad} />
                            : null
                        }
                    </TabPane>
                    <TabPane tab="Product Line" key="Product Line">
                        {currentSection == 'Product Line' ?
                            <ProductLine selectedYear={selectedYear} setSelectedYear={setSelectedYear} year={year} setYear={setYear} currentTab={currentSection} layoutSave={layoutSave} layoutLoad={layoutLoad}
                                setLayoutSave={setLayoutSave} setLayoutLoad={setLayoutLoad} />
                            : null
                        }
                    </TabPane>
                    <TabPane tab="PL YoY" key="PL YoY">
                        {currentSection == 'PL YoY' ?
                            <PlYoy selectedYear={selectedYear} setSelectedYear={setSelectedYear} year={year} setYear={setYear} currentTab={currentSection} layoutSave={layoutSave} layoutLoad={layoutLoad}
                                setLayoutSave={setLayoutSave} setLayoutLoad={setLayoutLoad} />
                            : null
                        }
                    </TabPane>
                    <TabPane tab="Commercial" key="Commercial">
                        {currentSection == 'Commercial' ?
                            <Commercial selectedYear={selectedYear} setSelectedYear={setSelectedYear} year={year} setYear={setYear} currentTab={currentSection} layoutSave={layoutSave} layoutLoad={layoutLoad}
                                setLayoutSave={setLayoutSave} setLayoutLoad={setLayoutLoad} />
                            : null
                        }
                    </TabPane>
                    <TabPane tab="Project Review" key="Project Review">
                        {currentSection == 'Project Review' ?
                            <ProjectReview selectedYear={selectedYear} setSelectedYear={setSelectedYear} year={year} setYear={setYear} currentTab={currentSection} layoutSave={layoutSave} layoutLoad={layoutLoad}
                                setLayoutSave={setLayoutSave} setLayoutLoad={setLayoutLoad} />
                            : null
                        }
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default GeneralTabs