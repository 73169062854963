/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Button, Tabs, Tooltip, Typography, Select, Row, Col, Card } from 'antd';

import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import history from '../../services/history';
import { fetchRawProjectSummeryList } from '../../redux/projectMargin/action';

const { Title,Text } = Typography;

const ProjectMarginCards = (props) => {

    const { onFetchRawProjectSummeryList, rawProjectSummeryList, isRawProjectSummeryListLoading } = props;

    useEffect(() => {
        if (rawProjectSummeryList.length == 0 && !isRawProjectSummeryListLoading) {
            onFetchRawProjectSummeryList({ tableName: 'Project_Summary_Consol', pageNumber: 1, pageSize: 60000 });
        }
    }, [])

    return (
        <div className="d-flex flex-column h-100">
            <div className="flex-auto">
                <div className="d-flex mb-2 align-items-center tab-content-title">
                    <div className='back_equip' >
                        <Tooltip title={'Back'} >
                            <ArrowLeftOutlined onClick={(e) => {
                                history.push('/FinanceMapping');
                            }} />
                        </Tooltip>
                    </div>
                    <Title className="mb-0 mt-1 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                        Project Margin
                    </Title>

                    <div className="new-calibration">
                        {<div>
                            {/* <Text className="ps-3 add-new-calibration" style={{ marginTop: '10px', marginRight: '20px' }}>Refresh Date: {'25 Nov 2024'}</Text>
                            <Button shape="round" key="apply" type="primary" style={{ marginRight: '10px' }}
                                // onClick={showMasterModal}
                                // onClick={() => {
                                //     history.push('/raw-data');
                                // }}
                            >
                               Refresh
                            </Button> */}
                            
                            <Button shape="round" key="apply" type="primary" style={{ marginRight: '10px' }}
                                // onClick={showMasterModal}
                                onClick={() => {
                                    history.push('/raw-data');
                                }}
                            >
                                Raw Data
                            </Button>
                        </div>
                        }

                    </div>
                </div>
            </div>
            <div className="custom-line" />
            <div >
                <Row gutter={16} className="detail-rows">
                    {/* <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/raw-data');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"Raw Data"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col> */}

                    <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/general-data');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"General"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/weekly-reporting');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"Weekly Reporting"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/me-reporting');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"ME Reporting"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col>


                    <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/brazil');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"Brazil"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={16} className="detail-rows">
                    <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/check1');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"Check1"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col>



                    <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/check2-commercial');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"Check2 Commercial"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    {/* <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/checks3');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"Check3"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col> */}




                    {/* <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/supplement');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"Supplemental"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col> */}

                </Row>
            </div>
        </div>
    );
};

const mapStateToProps = ({ projectMarginReducer }) => {
    const isRawProjectSummeryListLoading = get(projectMarginReducer, 'isRawProjectSummeryListLoading', false);
    const rawProjectSummeryList = get(projectMarginReducer, 'rawProjectSummeryList', []);

    return {
        isRawProjectSummeryListLoading,
        rawProjectSummeryList,
    }
}

const mapDispatchToProps = {
    onFetchRawProjectSummeryList: fetchRawProjectSummeryList,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMarginCards);
